import { useContext } from "react";
import axios from "axios";
import apiUrl from "Services/apiUrl";
import { UserContext } from "Providers/UserProvider";

export default function useSettings() {
  const { auth } = useContext(UserContext);

  async function getSimpleSetting({ name, lang = "ru" }) {
    try {
      const url = `${apiUrl}/api/settings/${name}`;

      const { data } = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": lang,
        },
      });
      return { success: true, data };
    } catch (error) {
      console.log(error);
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function getSetting({ name, lang = "ru" }) {
    try {
      const url = `${apiUrl}/api/admin/settings/${name}`;

      const { data } = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken()}`,
          "Accept-Language": lang,
        },
      });
      return { success: true, data };
    } catch (error) {
      console.log(error);
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function editSetting({ name, values, lang = "ru" }) {
    try {
      const { data } = await axios.patch(
        `${apiUrl}/api/admin/settings/${name}`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken()}`,
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function getTranslation({ lang = "ru" }) {
    try {
      const url = `${apiUrl}/api/admin/translation?lang=${lang}`;

      const { data } = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken()}`,
        },
      });
      return { success: true, data };
    } catch (error) {
      console.log(error);
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function postTranslation({ values, lang = "ru" }) {
    try {
      const url = `${apiUrl}/api/admin/translation`;

      const { data } = await axios.post(url, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken()}`,
        },
      });
      return { success: true, data };
    } catch (error) {
      console.log(error);
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  return {
    getSimpleSetting,
    getSetting,
    editSetting,
    getTranslation,
    postTranslation,
  };
}
