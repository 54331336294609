import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import apiUrl from "Services/apiUrl";

const fallbackLng = ["ru"];
const availableLanguages = ["en", "ru", "tj"];

const backendOptions = {
  // loadPath: "/locales/{{lng}}/{{ns}}.json",
  loadPath: `${apiUrl}/media/locales/{{lng}}_translation.json`,
};

i18n
  .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

  .use(LanguageDetector) // detect user language

  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init({
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
    initImmediate: false,
    react: {
      wait: true,
    },
    backend: backendOptions,
  });

export default i18n;

const t = i18n.t.bind(i18n);

export { t };
