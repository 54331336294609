import { useState, useEffect } from "react";
import Downshift from "downshift";
import axios from "axios";
import { useTranslation } from "react-i18next";
import apiUrl from "Services/apiUrl";
import useDebounce from "Hooks/useDebounce";

import {
  TextField,
  Paper,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import Clear from "@material-ui/icons/Clear";
import { StartIcon, FinishIcon, TranzitIcon } from "Components/SvgIcon";

import styles from "./styles.module.css";

const SingleLocation = ({
  icon,
  placeholder,
  selected,
  setSelected,
  excludeList,
  type = "all",
  label,
  error,
  actionClick = null,
  firstPicked = false,
  ...props
}) => {
  const { t, i18n } = useTranslation();

  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [searchText, setSearchText] = useState("");

  const debouncedSearchTerm = useDebounce(searchText, 400);

  const services = {
    all: (search) => {
      return `${apiUrl}/api/locations/?search=${search}`;
    },
    cities: (search) => {
      return `${apiUrl}/api/locations/cities?search=${search}`;
    },
  };

  const clearSearch = () => {
    setLoading(false);
    setSearchText("");
    setSelected(null);
    setLocations(null);
    setLoading(false);
    setNoResult(false);
  };

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true);
      try {
        setNoResult(false);
        const url = services[type](debouncedSearchTerm);
        const { data } = await axios.get(url, {
          headers: {
            "Accept-Language": i18n.language,
          },
        });
        arrangeData(data.results);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    if (debouncedSearchTerm.length > 1) fetchLocations();
  }, [debouncedSearchTerm]);

  const arrangeData = (data) => {
    setLocations([]);
    if (excludeList && data.length > 0) {
      data = data.filter(
        (item1) => !excludeList.some((item2) => item2.id === item1.id)
      );
    }
    if (data.length === 0) setNoResult(true);
    setLocations(data);
  };

  function getTitle(location) {
    let array = [];
    goDeeper(location);
    function goDeeper(location) {
      if (location.parent !== null) {
        array.push(location.name);
        goDeeper(location.parent);
        return;
      }
      array.push(location.name);
    }
    return array.join(", ");
  }

  function getTitleForString(location) {
    let array = [];
    goDeeper(location);
    function goDeeper(location) {
      if (location.parent !== null) {
        array.push(location.name);
        goDeeper(location.parent);
        return;
      }
      array.push(location.name);
    }
    const title = `${array.shift()}${array.length ? `, ${array.pop()}` : ""}`;

    return title;
  }

  const Loading = () => {
    return (
      <Paper className={styles.paper} square>
        <div style={{ padding: "1em" }}>{t("search.field.loading")}</div>
      </Paper>
    );
  };

  const NoResults = () => {
    return (
      <Paper className={styles.paper} square>
        <div style={{ padding: "1em" }}>{t("search.field.no_result")}</div>
      </Paper>
    );
  };

  const getCleaner = (reset) => {
    return (
      <InputAdornment position="end">
        <IconButton
          edge="end"
          onClick={() => {
            clearSearch();
            reset();
          }}
          tabIndex={-1}
        >
          <Clear />
        </IconButton>
      </InputAdornment>
    );
  };

  const getIcon = (icon) => {
    return (
      <InputAdornment position="start">
        {icon === "start" && <StartIcon />}
        {icon === "finish" && <FinishIcon />}
        {icon === "tranzit" && <TranzitIcon />}
      </InputAdornment>
    );
  };

  const inputChange = (event) => {
    const search = event.target.value.toLowerCase().trim();
    if (!search || search.length < 2) {
      clearSearch();
      return;
    }
    if (search.length > 1 && search !== searchText) setLoading(true);
    setSearchText(search);
  };

  return (
    <Downshift
      onChange={(item) => setSelected(item)}
      initialSelectedItem={selected ? selected : null}
      initialInputValue={selected ? getTitleForString(selected) : ""}
      itemToString={(selected) => (selected ? getTitleForString(selected) : "")}
      selectedItem={selected ? selected : null}
      onOuterClick={() => {
        if (locations && locations.length === 1) {
          setSelected(locations.pop());
        }
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        selectItem,
        setHighlightedIndex,
        reset,
      }) => (
        <div className={styles.container} tabIndex={-1}>
          <TextField
            {...getInputProps({
              label: label,
              error: Boolean(error),
              helperText: error && error,
              placeholder: placeholder,
              fullWidth: true,
              variant: "outlined",
              tabIndex: -1,
              onChange: (event) => inputChange(event),
              onKeyDown: (event) => {
                if (event.key === "Enter") {
                  if (locations && locations.length === 1) {
                    selectItem(locations.pop());
                    return;
                  }
                  if (selected && firstPicked && actionClick) {
                    actionClick();
                  }
                  event.nativeEvent.preventDownshiftDefault = false;
                } else if (event.key === "Tab") {
                  if (locations && locations.length === 1) {
                    selectItem(locations.pop());
                    return;
                  } else if (locations && locations.length !== 0) {
                    setHighlightedIndex(0);
                    return;
                  }
                  event.nativeEvent.preventDownshiftDefault = false;
                }
              },
              InputProps: {
                startAdornment: icon && getIcon(icon),
                endAdornment: getCleaner(reset),
              },
            })}
          />
          <div {...getMenuProps()}>
            {isOpen && (
              <>
                <Paper className={styles.paper} square>
                  {locations &&
                    locations.map((location, index) => (
                      <MenuItem
                        {...getItemProps({
                          index,
                          key: location.id,
                          component: "div",
                          item: location,
                          className: styles.menuItem,
                          style: {
                            backgroundColor:
                              highlightedIndex === index
                                ? "lightgray"
                                : "white",
                            fontWeight:
                              selectedItem === location ? "bold" : "normal",
                          },
                        })}
                      >
                        {getTitle(location)}
                      </MenuItem>
                    ))}
                </Paper>
                {inputValue && loading && <Loading />}
                {inputValue && !selected && noResult && <NoResults />}
              </>
            )}
          </div>
        </div>
      )}
    </Downshift>
  );
};

export default SingleLocation;
