import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";

const AboutMe = ({ about, onChange }) => {
  const { t } = useTranslation();

  return (
    <TextField
      fullWidth
      name="about"
      placeholder={t("profile.edit.main.about_text")}
      label={t("profile.edit.main.about_text")}
      multiline={true}
      rows={3}
      variant="outlined"
      value={about ? about : ""}
      onChange={onChange}
    />
  );
};

export default AboutMe;
