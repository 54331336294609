const SuspenseLoader = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        zIndex: "999",
      }}
    >
      <div className="animated-loader">
        <div style={{ backgroundColor: "black" }} />
        <div style={{ backgroundColor: "black" }} />
        <div style={{ backgroundColor: "black" }} />
        <div style={{ backgroundColor: "black" }} />
      </div>
    </div>
  );
};

export default SuspenseLoader;
