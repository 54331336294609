const getUrls = () => {
   if (window.location.hostname === "vezoo.tj" || window.location.hostname === "www.vezoo.tj") {
    return { apiUrl: "https://vezoo.tj", wsUrl: "wss://vezoo.tj" };
  } else if (window.location.hostname === "vezoo.uz" || window.location.hostname === "www.vezoo.uz") {
    return { apiUrl: "https://vezoo.uz", wsUrl: "wss://vezoo.uz" };
  } else if (window.location.hostname === "vezoo.kz" || window.location.hostname === "www.vezoo.kz") {
    return { apiUrl: "https://vezoo.kz", wsUrl: "wss://vezoo.kz" };
  } else if (window.location.hostname === "vezoo.kg" || window.location.hostname === "www.vezoo.kg") {
    return { apiUrl: "https://vezoo.kg", wsUrl: "wss://vezoo.kg" };
  } else if (window.location.hostname === "vezoo.io" || window.location.hostname === "www.vezoo.io") {
    return { apiUrl: "https://vezoo.io", wsUrl: "wss://vezoo.io" };
  } else if (window.location.hostname === "http://localhost") {
    return { apiUrl: "http://localhost:8000", wsUrl: "wss://localhost" };
  }
  else  {
    return { apiUrl: "http://127.0.0.1:8000", wsUrl: "wss://127.0.0.1" };
  }
};

const { apiUrl, wsUrl } = getUrls();

export { apiUrl as default, wsUrl };
