import BaseModal from "Modals/Base";
import DateTimePicker from "Components/DateTimePicker";

export default function DateTimePickerModal({
  open,
  handleClose,
  date,
  handleDateChange,
  maxValue,
  minValue,
}) {
  return (
    <BaseModal
      noPadding={true}
      closeButton={false}
      maxWidth="md"
      open={open}
      handleClose={handleClose}
    >
      <DateTimePicker
        value={date}
        changeValue={handleDateChange}
        handleClose={handleClose}
        maxValue={maxValue}
        minValue={minValue}
      />
    </BaseModal>
  );
}
