import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/styles";
import apiUrl from "Services/apiUrl";

const SvgIconReact = ({ icon, size }) => {
  const divStyle = {
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
  };

  return (
    <div style={divStyle}>
      <img
        style={{ fontSize: `${size}em` }}
        src={`${icon}`}
        draggable="false"
      />
    </div>
  );
};

const SvgIcon = ({ icon, size }) => {
  const divStyle = {
    display: "inline-block",
    verticalAlign: "middle",
  };

  return (
    <div style={divStyle}>
      <Icon>
        <img
          style={{ margin: "8px", width: `${size}em`, height: `${size}em` }}
          src={`${apiUrl}/${icon}`}
        />
      </Icon>
    </div>
  );
};

const SvgMenuIcon = ({ icon, staticIcon = false }) => {
  const divStyle = {
    height: "28px",
    width: "28px",
    display: "inline-block",
    minWidth: "40px",
    verticalAlign: "middle",
  };

  return (
    <div style={divStyle}>
      <img
        style={{ height: "100%" }}
        src={staticIcon ? `${icon}` : `${apiUrl}/${icon}`}
      />
    </div>
  );
};

const StartIcon = () => {
  const divStyle = {
    display: "inline-block",
    verticalAlign: "middle",
  };

  return (
    <div style={divStyle}>
      <Icon>
        <img
          style={{ marginRight: "4px", width: `1em`, height: `1em` }}
          src={`/icons/location_points/start.svg`}
        />
      </Icon>
    </div>
  );
};

const TranzitIcon = () => {
  const divStyle = {
    display: "inline-block",
    verticalAlign: "middle",
  };

  return (
    <div style={divStyle}>
      <Icon>
        <img
          style={{ marginRight: "4px", width: `1em`, height: `1em` }}
          src={`/icons/location_points/tranzit.svg`}
        />
      </Icon>
    </div>
  );
};

const FinishIcon = () => {
  const divStyle = {
    display: "inline-block",
    verticalAlign: "middle",
  };

  return (
    <div style={divStyle}>
      <Icon>
        <img
          style={{ marginRight: "4px", width: `1em`, height: `1em` }}
          src={`/icons/location_points/finish.svg`}
        />
      </Icon>
    </div>
  );
};

export {
  SvgIcon as default,
  SvgIconReact,
  SvgMenuIcon,
  StartIcon,
  FinishIcon,
  TranzitIcon,
};
