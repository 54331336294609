import { useEffect, useState, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { format, compareAsc, parseISO } from "date-fns";

import { UserContext } from "Providers/UserProvider";
import { MainContext } from "Providers/MainProvider";
import UploadImages from "./newUploadFiles";

import {
  Typography,
  TextField,
  Grid,
  Container,
  Button,
  InputAdornment,
} from "@material-ui/core";

import { SingleLocation } from "Components/Selects/";

import { ReadyToPay } from "Components/Inputs/";
import { ValidatorForm } from "react-material-ui-form-validator";
import EventIcon from "@material-ui/icons/Event";
import DateTimePickerModal from "Modals/DateTime";
import { WeightTypes, FreightTypes } from "Components/Selects";
import useShipments from "Hooks/useShipments";
import imageCompression from "browser-image-compression";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getDateLocale } from "Components/DateLocales/";

export default function AddEditShipment({
  depStartLocation,
  depFinishLocation,
  minDepArrival,
  loading,
  setLoading,
  setLoadingLabel,
  onSuccess,
  onSuccessEdit,
  mode,
  id,
  choose,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { refreshShipments, makeRefreshShipments } = useContext(MainContext);
  const [tab, setTab] = useState(1);
  const { user } = useContext(UserContext);
  const shipmentServices = useShipments();
  const { t, i18n } = useTranslation();

  const [startLocation, setStartLocation] = useState(null);
  const [finishLocation, setFinishLocation] = useState(null);

  const [deadlineTimeModalOpen, setDeadlineTimeModalOpen] = useState(false);

  const [values, setValues] = useState({
    freight_type: "",
    max_weight: "",
    description: "",
    deadline_date: "",
    price: "",
    photo: null,
    is_visible: true,
    currency: "",
  });

  const [images, setImages] = useState([]);

  const addImage = (newImage) => {
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 777,
      useWebWorker: true,
    };
    imageCompression(newImage, options)
      .then(function (compressedFile) {
        setImages((oldImages) => {
          if (oldImages.some((image) => image.name === compressedFile.name)) {
            return oldImages;
          }

          const copyImages = [...oldImages];

          copyImages.push(compressedFile);

          if (copyImages.length > 3) {
            copyImages.shift();
          }

          return copyImages;
        });
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  // setImages((oldImages) => ([...oldImages, newImage]))
  const removeImage = (event, remFile) => {
    event.stopPropagation();
    setImages(images.filter((file) => file.name != remFile.name));
  };

  const [errors, setErrors] = useState({
    freight_type: null,
    max_weight: null,
    deadline_date: null,
  });

  const [serverError, setServerError] = useState(null);

  const [startLocationError, setStartLocationError] = useState(null);
  const [finishLocationError, setFinishLocationError] = useState(null);

  const [currency, setCurrency] = useState(6);

  useEffect(() => {
    if (depStartLocation && depFinishLocation) {
      setStartLocation(depStartLocation);
      setFinishLocation(depFinishLocation);
    }
  }, [depStartLocation, depFinishLocation]);

  useEffect(() => {
    if (user && user.profile && user.profile.currency) {
      setCurrency(user.profile.currency.id);
    }
  }, [user]);

  function handleSettingsChange(event) {
    event.persist();
    setCurrency(event.target.value);
  }

  function dateTimeDeadChange(value) {
    setValues((oldValues) => ({
      ...oldValues,
      deadline_date: value,
    }));
    setErrors((oldErrors) => ({
      ...oldErrors,
      deadline_date: null,
    }));
    if (minDepArrival && compareAsc(value, parseISO(minDepArrival)) === -1) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        deadline_date: t("shipments.create.errors.arrival_gt_deadline_times"),
      }));
    }
  }

  function setPhotoFiles(files) {
    setValues((oldValues) => ({
      ...oldValues,
      photo: files,
    }));
  }

  function handleChange(event) {
    event.persist();
    setErrors((oldErrors) => ({
      ...oldErrors,
      [event.target.name]: null,
    }));
    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  }

  const handleCheckBoxChange = (name) => (event) => {
    event.persist();
    setValues((oldValues) => ({
      ...oldValues,
      [name]: event.target.checked,
    }));
  };

  const createShipment = () => {
    setLoading(true);
    setLoadingLabel(t("common.loaders.add_shipment"));
    setServerError(null);

    let locations = [
      { id: startLocation.id, order: 1 },
      { id: finishLocation.id, order: null },
    ];
    let send_values = {
      ...values,
      locations: locations,
    };

    let formData = new FormData();

    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("photo", images[i], images[i].name);
      }
    }
    if (send_values.price) {
      formData.append("currency", currency);
      formData.append("price", send_values.price);
    }
    formData.append("locations", JSON.stringify(send_values.locations));
    formData.append("freight_type", send_values.freight_type);
    formData.append("max_weight", send_values.max_weight);
    formData.append("deadline_date", JSON.stringify(send_values.deadline_date));
    formData.append("is_visible", send_values.is_visible);
    formData.append("description", send_values.description);

    shipmentServices
      .addShipment({ values: formData, lang: i18n.language })
      .then(({ success, data, error, status }) => {
        if (success) {
          !choose && makeRefreshShipments(refreshShipments + 1);
          onSuccess(data);
        } else {
          status ? setServerError(status) : setServerError("0");
          error && console.log(error);
        }
        setLoading(false);
        setLoadingLabel("");
      });
  };

  const editShipment = () => {
    setLoading(true);
    setLoadingLabel(t("common.loaders.edit_shipment"));
    setServerError(null);

    let locations = [
      { id: startLocation.id, order: 1 },
      { id: finishLocation.id, order: null },
    ];
    let send_values = {
      ...values,
      locations: locations,
    };

    let formData = new FormData();
    if (send_values.price) {
      formData.append("currency", currency);
      formData.append("price", send_values.price);
    }
    formData.append("locations", JSON.stringify(send_values.locations));
    formData.append("freight_type", send_values.freight_type);
    formData.append("max_weight", send_values.max_weight);
    formData.append("deadline_date", JSON.stringify(send_values.deadline_date));
    formData.append("is_visible", send_values.is_visible);
    formData.append("description", send_values.description);

    shipmentServices
      .editShipment({ id: id, values: formData, lang: i18n.language })
      .then(({ success, data, error, status }) => {
        setLoading(false);
        if (success) {
          onSuccessEdit(data);
        } else {
          error && console.log(error);
        }
      });
  };

  function handleSubmit(event) {
    event.preventDefault();
    let error = false;
    let errorTab = null;
    Object.entries(errors).forEach(([key, value]) => {
      if (!values[key]) {
        setErrors((oldErrors) => ({
          ...oldErrors,
          [key]: t("shipments.create.errors.not_null"),
        }));
        error = true;
        errorTab = 2;
        if (key === "deadline_date") errorTab = 1;
      } else {
        setErrors((oldErrors) => ({
          ...oldErrors,
          [key]: null,
        }));
      }
    });
    if (!startLocation) {
      setStartLocationError(t("shipments.create.errors.not_null"));
      error = true;
      errorTab = 1;
    }
    if (!finishLocation) {
      setFinishLocationError(t("shipments.create.errors.not_null"));
      error = true;
      errorTab = 1;
    }
    if (error) {
      errorTab === 1 && setTab(1);
      errorTab === 2 && setTab(2);
      return false;
    }
    mode === "add" ? createShipment() : editShipment();
    return false;
  }

  const makeExcludeList = (initiator) => {
    let result = [];
    switch (initiator) {
      case "start":
        finishLocation ? (result = [finishLocation]) : (result = []);
        return result;
      case "finish":
        startLocation ? (result = [startLocation]) : (result = []);
        return result;
    }
  };

  const parseData = (data) => {
    const routelocations = data.route.routelocations;
    const startLoc = routelocations.shift();
    const finishLoc = routelocations.pop();
    setStartLocation(startLoc.location);
    setFinishLocation(finishLoc.location);

    const result = {
      freight_type: data.freight_type.id,
      max_weight: data.max_weight.id,
      description: data.description,
      deadline_date: data.deadline_date,
      photo: data.photo,
      is_visible: data.is_visible,
    };
    if (data.price) {
      result.currency = data.currency;
      result.price = data.price;
    }
    setValues(result);
  };

  useEffect(() => {
    if (mode === "edit") {
      setLoading(true);
      setLoadingLabel(t("common.loaders.info"));

      const fetchData = () => {
        shipmentServices
          .getShipment({ id, lang: i18n.language })
          .then(({ success, data, error, status }) => {
            if (success) {
              parseData(data);
            }
            setLoading(false);
          });
      };

      fetchData();
    }
  }, [mode, i18n.language]);

  return (
    <Container className="padding0">
      <ValidatorForm onSubmit={handleSubmit} className="fullWidthForm">
        <Grid item container spacing={2} direction="column">
          {tab === 1 && (
            <Fragment>
              <Grid item className="marginBottom8">
                <Typography variant="h6" component="h6">
                  {t("shipments.create.route")}
                </Typography>
              </Grid>

              <Grid item className="marginBottom8">
                <SingleLocation
                  icon="start"
                  error={startLocationError}
                  label={t("shipments.create.from.label")}
                  placeholder={t("shipments.create.from.placeholder")}
                  type="cities"
                  selected={startLocation}
                  setSelected={(selected) => {
                    setStartLocation(selected);
                    setStartLocationError(false);
                  }}
                  excludeList={makeExcludeList("start")}
                />
              </Grid>
              <Grid item className="marginBottom8">
                <SingleLocation
                  icon="finish"
                  error={finishLocationError}
                  label={t("shipments.create.to.label")}
                  placeholder={t("shipments.create.to.placeholder")}
                  type="cities"
                  selected={finishLocation}
                  setSelected={(selected) => {
                    setFinishLocation(selected);
                    setFinishLocationError(false);
                  }}
                  excludeList={makeExcludeList("finish")}
                />
              </Grid>
              <Grid item className="marginBottom8">
                {isMobile ? (
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={getDateLocale(i18n.language)}
                  >
                    <DateTimePicker
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EventIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      inputVariant="outlined"
                      fullWidth
                      label={t("shipments.create.deadline_date")}
                      placeholder={t("shipments.create.deadline_date")}
                      disablePast
                      ampm={false}
                      value={values.deadline_date}
                      onChange={dateTimeDeadChange}
                      name="deadline_date"
                      error={Boolean(errors.deadline_date)}
                      locale={i18n.language}
                      minutesStep={5}
                      helperText={errors.deadline_date && errors.deadline_date}
                      minDate={minDepArrival && minDepArrival}
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EventIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    value={
                      values.deadline_date &&
                      format(
                        Date.parse(values.deadline_date),
                        "dd.MM.yyyy HH:mm"
                      )
                    }
                    variant="outlined"
                    label={t("shipments.create.deadline_date")}
                    placeholder={t("shipments.create.deadline_date")}
                    onClick={() => setDeadlineTimeModalOpen(true)}
                    helperText={errors.deadline_date && errors.deadline_date}
                    error={Boolean(errors.deadline_date)}
                  />
                )}

                {deadlineTimeModalOpen && (
                  <DateTimePickerModal
                    open={deadlineTimeModalOpen}
                    handleClose={() => setDeadlineTimeModalOpen(false)}
                    date={values.deadline_date}
                    handleDateChange={dateTimeDeadChange}
                    minValue={minDepArrival && minDepArrival}
                  />
                )}
              </Grid>
              <Grid item style={{ textAlign: "center" }}>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    margin: "8px",
                  }}
                  onClick={() => setTab(2)}
                >
                  {t("shipments.create.next")}
                </Button>
              </Grid>
            </Fragment>
          )}
          {tab === 2 && (
            <Fragment>
              <Grid item className="marginBottom8">
                <Typography variant="h6" component="h6">
                  {t("shipments.create.info")}
                </Typography>
              </Grid>

              <Grid item className="marginBottom8">
                <WeightTypes
                  max_weight={values.max_weight}
                  handleChange={handleChange}
                  error={errors.max_weight}
                />
              </Grid>
              <Grid item className="marginBottom8">
                <FreightTypes
                  freight_type={values.freight_type}
                  handleChange={handleChange}
                  error={errors.freight_type}
                />
              </Grid>

              <Grid item className="marginBottom8">
                <Typography variant="h6" component="h6">
                  {t("shipments.create.price")}
                </Typography>
                <Typography variant="subtitle2" component="p">
                  {t("shipments.create.treaty_text")}
                </Typography>
              </Grid>
              <Grid item container className="marginBottom8">
                <ReadyToPay
                  price={values.price}
                  onChange={handleChange}
                  currency={currency}
                  currencyOnChange={handleSettingsChange}
                />
              </Grid>
              <Grid item style={{ textAlign: "center" }}>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    margin: "8px",
                  }}
                  onClick={() => setTab(1)}
                >
                  {t("shipments.create.back")}
                </Button>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    margin: "8px",
                  }}
                  onClick={() => setTab(3)}
                >
                  {t("shipments.create.next")}
                </Button>
              </Grid>
            </Fragment>
          )}
          {/* <Grid item className='marginBottom8'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.is_visible}
                      onChange={handleCheckBoxChange('is_visible')}
                      name='is_visible'
                      color='primary'
                    />
                  }
                  label={t('shipments.create.is_visible')}
                />
              </Grid> */}
          {tab === 3 && (
            <Fragment>
              <Grid item className="marginBottom8">
                <Typography variant="h6" component="h6">
                  {t("shipments.create.photo.title")}
                </Typography>
                {mode === "edit" ? (
                  <Typography variant="subtitle2" component="p">
                    {t("shipments.create.photo.edit_tip")}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" component="p">
                    {t("shipments.create.photo.tip")}
                  </Typography>
                )}
              </Grid>
              {mode !== "edit" && (
                <Grid item className="marginBottom8">
                  <UploadImages
                    images={images}
                    addImage={addImage}
                    removeImage={removeImage}
                  />
                </Grid>
              )}

              <Grid item className="marginBottom8">
                <Typography variant="h6" component="h6">
                  {t("shipments.create.description.label")}
                </Typography>
              </Grid>

              <Grid item className="marginBottom8">
                <TextField
                  id="descriptionText"
                  value={values.description}
                  name="description"
                  placeholder={t("shipments.create.description.placeholder")}
                  fullWidth
                  multiline={true}
                  rows={3}
                  variant="outlined"
                  onChange={handleChange}
                  inputProps={{ maxLength: 150 }}
                />
              </Grid>
              <Grid
                item
                className="marginBottom8"
                style={{ textAlign: "center" }}
              >
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    margin: "8px",
                  }}
                  onClick={() => setTab(2)}
                >
                  {t("shipments.create.back")}
                </Button>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    margin: "8px",
                  }}
                  type="submit"
                >
                  {mode === "edit"
                    ? t("shipments.edit.action_button")
                    : t("shipments.create.action_button")}
                </Button>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </ValidatorForm>
    </Container>
  );
}
