import styles from "./base.module.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { LoadingOverlay } from "Containers/Overlay";

const useStyles = makeStyles((theme) => ({
  modalHeaderContainer: {
    background: theme.palette.primary.main,
    color: theme.home.hero.color,
  },

  modalHeaderCloseButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: theme.home.hero.color,
  },
}));

const BaseModal = ({
  open,
  handleClose,
  title,
  loading,
  loadingLabel,
  fullWidth = true,
  maxWidth = "sm",
  closeButton = true,
  actionButton = null,
  actionButtonLabel = "",
  actionButton2 = null,
  actionButtonLabel2 = "",
  success,
  successLabel,
  children,
  noPadding = false,
  disableBackdropClick = false,
  allowFullScreen = true,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();
  const classes = useStyles();

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={allowFullScreen && fullScreen}
      disableBackdropClick={disableBackdropClick}
      aria-labelledby="dialog-title"
    >
      {title && (
        <DialogTitle
          disableTypography
          id="dialog-title"
          className={classes.modalHeaderContainer}
        >
          <Typography variant="h6">{title}</Typography>
          {closeButton && (
            <IconButton
              aria-label="close"
              className={classes.modalHeaderCloseButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent
        style={{ padding: noPadding && "0px", marginTop: !title && "14px" }}
      >
        {children}
        {loading && <LoadingOverlay text={loadingLabel} />}

        {success && (
          <div className="overlayContainer">
            <Grid
              container
              justify="center"
              alignContent="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Grid item align="center">
                <Typography variant="h5" className="marginBottom16">
                  {successLabel}
                </Typography>
                <Button
                  size="large"
                  variant="outlined"
                  onClick={handleClose}
                  className="margin8"
                >
                  {t("common.close")}
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {actionButton && (
          <Button onClick={actionButton} color="primary">
            {actionButtonLabel}
          </Button>
        )}
        {actionButton2 && (
          <Button onClick={actionButton2} color="primary">
            {actionButtonLabel2}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BaseModal;
