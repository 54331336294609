import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { useTranslation } from "react-i18next";
import useSettings from "Hooks/useSettings";
import { useState, useEffect } from "react";
import BaseModal from "Modals/Base";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export default function WelcomeModal({ open, handleClose }) {
  const { t, i18n } = useTranslation();
  const [terms, setTerms] = useState({
    name: "welcome_video",
    value: "",
    modified: new Date(),
  });
  const [loading, setLoading] = useState(false);
  const settingsServices = useSettings();

  function hideWelcomeMessage() {
    const siteVisits = getCookie("welcome");
    document.cookie = `welcome=${parseFloat(siteVisits) + 5}`;
    handleClose();
  }

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      settingsServices
        .getSimpleSetting({
          name: "welcome_video",
          lang: i18n.language,
        })
        .then(({ success, data, error, status }) => {
          if (success) {
            setTerms(data);
          } else {
            console.log(error);
          }
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <BaseModal
      maxWidth="sm"
      open={open}
      handleClose={handleClose}
      title={t("welcome.title")}
      allowFullScreen={false}
    >
      <Grid container direction="column">
        <Typography variant="body1">{t("welcome.subtitle")}</Typography>
        <div style={{ margin: "30px 0" }}>
          {!loading ? (
            <iframe
              width="100%"
              height="315"
              src={terms ? terms.value : null}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <></>
          )}
        </div>
        <Button variant="outlined" onClick={hideWelcomeMessage}>
          {t("welcome.hide")}
        </Button>
      </Grid>
    </BaseModal>
  );
}
