import { makeStyles } from "@material-ui/styles";

// Цвета сайта FFA500
// const primaryColor = '#009688';
// const primaryColor = "#64ceff";
const primaryColor = "#2681CC";
// const secondaryColor = '#c1e5e2';
const secondaryColor = "#64ceff";
// const darkBackColor = '#00544a';
const darkBackColor = "#64ceff";
// const iconColor = "#25779c";
const iconColor = "#2681CC";

const titleColor = "#64ceff";

const globalStyles = makeStyles({
  "@global": {
    // Стиль кнопки
    ".submitButton": {
      backgroundColor: primaryColor,
      color: "white",
      borderRadius: "5px",
      margin: "24px 0px 16px",
      padding: "16px",
      "&:hover": {
        color: "white",
        backgroundColor: primaryColor,
      },
    },
    ".icon": {
      color: iconColor,
    },
    ".overlayContainer": {
      backgroundColor: "rgba(142, 219, 255, 0.90)",
      position: "absolute",
      top: "0",
      bottom: "0",
      zIndex: "1000",
      overflow: "hidden",
      width: "100%",
      height: "100%",
      left: "0",
      transition: "300ms",
    },
    ".pageTitle": {
      backgroundColor: titleColor,
      padding: "24px 0 24px",
    },
    ".pageContent": {
      position: "relative",
      padding: "32px 16px",
    },
    // ie issue
    ".fullWidthForm": {
      width: "100%",
      display: "flex",
    },
    ".marginBottom16": {
      marginBottom: "16px",
    },
    ".marginBottom8": {
      marginBottom: "8px",
    },
    ".marginTop16": {
      marginTop: "16px",
    },
    ".marginTop8": {
      marginTop: "8px",
    },
    ".fullWidthGrid": {
      width: "100%",
    },
    ".padding0": {
      padding: 0,
    },
    ".padding8": {
      padding: 8,
    },
    ".padding16": {
      padding: "16px",
    },
    ".margin8": {
      margin: "8px",
    },
    ".card": {
      cursor: "pointer",
      width: "auto",
      padding: "14px",
      // margin: "8px",
      marginBottom: "16px",
      marginTop: "8px",
      color: "#505050",
      textDecoration: "inherit",
      borderRadius: "8px",
      boxShadow:
        "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    },
    ".cardIcon": {
      margin: "8px 8px 5px 8px",
      color: iconColor,
    },
    ".tabRoot": {
      flexGrow: 1,
      minHeight: "min-content",
      padding: 0,
    },
    ".select-comp": {
      padding: "12px 14px",
    },
    // ".MuiOutlinedInput-input": {
    //   padding: "14px 7px"
    // },
  },
});

export { globalStyles };
