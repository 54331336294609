import { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import { UserContext } from "Providers/UserProvider";

import LinkRef from "Components/LinkRef";
import { MainContext } from "Providers/MainProvider";

import {
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Menu,
  Hidden,
  IconButton,
  Divider,
  Icon,
  ListItemAvatar,
  ListItemIcon,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import ListIcon from "@material-ui/icons/List";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationService from "Components/Header/Notifications/";
import UserAvatar, { HeaderUserAvatar } from "Components/UserAvatar";

import { useHistory } from "react-router-dom";

import AddEditDepartureModal from "Modals/AddEditDeparture";
import AddEditShipmentModal from "Modals/AddEditShipment";
import useProfile from "Hooks/useProfile";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    marginLeft: "auto",
  },
  listIText: {
    whiteSpace: "nowrap",
    marginLeft: "5px",
    color: "#859098",
    textDecoration: "none",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "500",
    float: "left",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  userMenu: {
    padding: "10px",
    color: "black",
  },
  imageIcon: {
    height: "100%",
    border: "1px solid #d0d0d0",
    borderRadius: "50%",
  },
  listItemRoot: {
    minWidth: "40px",
  },
  logo: {
    // maxWidth: 145
    maxHeight: "65px",
    margin: "8px",
  },
}));

const countryParams = {
  ru: "russia",
  en: "united-kingdom",
  tj: "tajikistan",
};

function GetCountryIcon(props) {
  const classes = useStyles();

  return (
    <Icon>
      <img
        alt={props.country}
        className={classes.imageIcon}
        src={`/icons/countries/${countryParams[props.country]}.svg`}
      />
    </Icon>
  );
}

function Header() {
  const {
    refreshDepartures,
    makeRefreshDepartures,
    refreshShipments,
    makeRefreshShipments,
  } = useContext(MainContext);

  const classes = useStyles();
  const { user, auth } = useContext(UserContext);
  let lang = user && user.profile ? user.profile.language : null;

  const { t, i18n } = useTranslation();

  const history = useHistory();

  if (!lang) lang = i18n.language;

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  const profileServices = useProfile();

  const changeLang = (newLang) => {
    if (user) {
      profileServices
        .editProfile({ values: { language: newLang }, lang: newLang })
        .then(({ success }) => {
          if (success) {
            auth.getUserData();
          } else {
            console.log("Language did not update!");
          }
        });
    } else {
      lang = newLang;
      i18n.changeLanguage(newLang);
    }
  };

  const useMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
      setAnchorEl(null);
    };

    return { open, anchorEl, handleMenu, onClose };
  };

  const { handleMenu: handleLangMenu, ...LangMenu } = useMenu();
  const { handleMenu: handleAccountMenu, ...AccountMenu } = useMenu();
  const { handleMenu: handleHamburgerMenu, ...HamburgerMenu } = useMenu();

  function LogOut() {
    auth.logout();
    history.push("/");
  }

  const [addDeparture, setAddDeparture] = useState(false);
  const [addShipment, setAddShipment] = useState(false);

  const MainMenuDestop = () => {
    return (
      <Hidden smDown>
        <ListItem
          style={{ cursor: "pointer" }}
          onClick={() => {
            makeRefreshDepartures(refreshDepartures + 1);
            history.push("/departures");
          }}
        >
          <ListItemText
            disableTypography
            primary={t("header.m1")}
            className={`${classes.listIText} apply-font-secondary`}
          />
        </ListItem>
        <ListItem
          style={{ cursor: "pointer" }}
          onClick={() => (user ? setAddDeparture(true) : history.push("/join"))}
          // component={LinkRef}
          // to={{ pathname: `/create/departures`, target: "_blank" }}
        >
          <ListItemText
            disableTypography
            primary={t("header.m2")}
            className={`${classes.listIText} apply-font-secondary`}
          />
        </ListItem>

        <ListItem
          style={{ cursor: "pointer" }}
          onClick={() => {
            makeRefreshShipments(refreshShipments + 1);
            history.push("/shipments");
          }}
        >
          <ListItemText
            disableTypography
            primary={t("header.m3")}
            className={`${classes.listIText} apply-font-secondary`}
          />
        </ListItem>
        <ListItem
          style={{ cursor: "pointer" }}
          onClick={() => (user ? setAddShipment(true) : history.push("/join"))}
          // component={LinkRef}
          // to={{ pathname: `/create/shipments`, target: "_blank" }}
        >
          <ListItemText
            disableTypography
            primary={t("header.m4")}
            className={`${classes.listIText} apply-font-secondary`}
          />
        </ListItem>
      </Hidden>
    );
  };

  const MainMenuMobile = () => {
    function addDepartureMobile() {
      if (user) {
        setAddDeparture(true);
        HamburgerMenu.onClose();
      } else {
        history.push("/join");
      }
    }

    function addShipmentMobile() {
      if (user) {
        setAddShipment(true);
        HamburgerMenu.onClose();
      } else {
        history.push("/join");
      }
    }

    return (
      <Hidden mdUp>
        <IconButton
          aria-label="Account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleHamburgerMenu}
        >
          <ListIcon />
        </IconButton>
        <Menu
          className={classes.userMenu}
          id="menu-appbar"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          {...HamburgerMenu}
        >
          <ListItem
            button
            onClick={() => {
              makeRefreshDepartures(refreshDepartures + 1);
              history.push("/departures");
              HamburgerMenu.onClose();
            }}
          >
            <ListItemText
              disableTypography
              primary={t("header.m1")}
              className={`${classes.listIText} apply-font-secondary`}
            />
          </ListItem>
          <ListItem button onClick={addDepartureMobile}>
            <ListItemText
              disableTypography
              primary={t("header.m2")}
              className={`${classes.listIText} apply-font-secondary`}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              makeRefreshShipments(refreshShipments + 1);
              history.push("/shipments");
              HamburgerMenu.onClose();
            }}
          >
            <ListItemText
              disableTypography
              primary={t("header.m3")}
              className={`${classes.listIText} apply-font-secondary`}
            />
          </ListItem>
          <ListItem button onClick={addShipmentMobile}>
            <ListItemText
              disableTypography
              primary={t("header.m4")}
              className={`${classes.listIText} apply-font-secondary`}
            />
          </ListItem>
        </Menu>
      </Hidden>
    );
  };

  const AuthMenuMobile = () => {
    return (
      <>
        <IconButton
          aria-label="Account of current user"
          aria-controls="menu-appbar212"
          aria-haspopup="true"
          onClick={handleAccountMenu}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          className={classes.userMenu}
          id="menu-appbar212"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          {...AccountMenu}
        >
          <ListItem
            component={LinkRef}
            to={{ pathname: `/login`, target: "_blank" }}
          >
            <ListItemText
              disableTypography
              primary={t("header.m5")}
              className={`${classes.listIText} apply-font-secondary`}
              onClick={AccountMenu.onClose}
            />
          </ListItem>
          <ListItem
            component={LinkRef}
            to={{ pathname: `/registration`, target: "_blank" }}
          >
            <ListItemText
              disableTypography
              primary={t("header.m6")}
              className={`${classes.listIText} apply-font-secondary`}
              onClick={AccountMenu.onClose}
            />
          </ListItem>
        </Menu>
      </>
    );
  };

  const AuthMenuDestop = (currentUser) => {
    if (currentUser && currentUser.profile)
      return (
        <>
          <NotificationService />
          <IconButton
            aria-label="Account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleAccountMenu}
          >
            {/* <AccountCircle /> */}

            <HeaderUserAvatar profile={currentUser.profile} size={1.3} />
          </IconButton>
          <Menu
            className={classes.userMenu}
            id="menu-appbar"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            {...AccountMenu}
          >
            <ListItem>
              <Hidden xsDown>
                <ListItemAvatar>
                  <UserAvatar profile={currentUser.profile} />
                </ListItemAvatar>
              </Hidden>
              <ListItemText
                primary={
                  currentUser.profile.first_name +
                  " " +
                  currentUser.profile.last_name
                }
                secondary={currentUser.phone_number}
              />
            </ListItem>
            <Divider />
            {currentUser.urp === "a919" && (
              <ListItem
                button
                component={LinkRef}
                to={{ pathname: `/va/users`, target: "_blank" }}
                onClick={AccountMenu.onClose}
              >
                <ListItemText
                  disableTypography
                  primary={t("header.admin_panel")}
                  className={`${classes.listIText} apply-font-secondary`}
                />
              </ListItem>
            )}
            <ListItem
              button
              component={LinkRef}
              to={{ pathname: `/settings`, target: "_blank" }}
              onClick={AccountMenu.onClose}
            >
              <ListItemText
                disableTypography
                primary={t("header.m7")}
                className={`${classes.listIText} apply-font-secondary`}
              />
            </ListItem>
            <ListItem
              button
              component={LinkRef}
              to={{ pathname: `/me`, target: "_blank" }}
              onClick={AccountMenu.onClose}
            >
              <ListItemText
                disableTypography
                primary={t("header.m8")}
                className={`${classes.listIText} apply-font-secondary`}
              />
            </ListItem>
            <ListItem
              button
              className={classes.listI}
              onClick={() => {
                AccountMenu.onClose();
                LogOut();
              }}
            >
              <ListItemText
                disableTypography
                primary={t("header.m9")}
                className={`${classes.listIText} apply-font-secondary`}
              />
            </ListItem>
          </Menu>
        </>
      );
    else
      return (
        <>
          <Hidden smDown>
            <ListItem
              component={LinkRef}
              to={{ pathname: `/login`, target: "_blank" }}
            >
              <ListItemText
                disableTypography
                primary={t("header.m5")}
                className={`${classes.listIText} apply-font-secondary`}
              />
            </ListItem>
            <ListItem
              component={LinkRef}
              to={{ pathname: `/registration`, target: "_blank" }}
            >
              <ListItemText
                disableTypography
                primary={t("header.m6")}
                className={`${classes.listIText} apply-font-secondary`}
              />
            </ListItem>
          </Hidden>
          <Hidden mdUp>
            <AuthMenuMobile />
          </Hidden>
        </>
      );
  };

  return (
    <AppBar className={classes.appBar} position="relative">
      <Toolbar>
        <RouterLink
          to="/"
          style={{ display: "inline-flex", textDecoration: "none" }}
        >
          <img
            src="/logo.png"
            alt="logo"
            className={classes.logo}
            style={{ verticalAlign: "middle" }}
          />
        </RouterLink>

        <List className={classes.flexContainer} component="nav">
          {/* Основное меню - десктоп */}
          {MainMenuDestop()}
          {/* Основное меню - мобайл */}
          {MainMenuMobile()}
          {/* Меню авторизации - десктоп */}
          {/* Не авторизован */}
          {AuthMenuDestop(user)}

          <IconButton
            aria-label="Account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleLangMenu}
          >
            <GetCountryIcon country={lang} />
          </IconButton>
          <Menu
            className={classes.userMenu}
            id="menu-appbar"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            {...LangMenu}
          >
            <ListItem
              button
              onClick={() => {
                changeLang("ru");
                LangMenu.onClose();
              }}
            >
              <ListItemIcon classes={{ root: classes.listItemRoot }}>
                <GetCountryIcon country="ru" />
              </ListItemIcon>
              <ListItemText primary="Русский" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                changeLang("en");
                LangMenu.onClose();
              }}
            >
              <ListItemIcon classes={{ root: classes.listItemRoot }}>
                <GetCountryIcon country="en" />
              </ListItemIcon>
              <ListItemText primary="English" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                changeLang("tj");
                LangMenu.onClose();
              }}
            >
              <ListItemIcon classes={{ root: classes.listItemRoot }}>
                <GetCountryIcon country="tj" />
              </ListItemIcon>
              <ListItemText primary="Тоҷикӣ" />
            </ListItem>
          </Menu>
        </List>
      </Toolbar>
      {addDeparture && (
        <AddEditDepartureModal
          open={addDeparture}
          handleClose={() => setAddDeparture(false)}
        />
      )}
      {addShipment && (
        <AddEditShipmentModal
          open={addShipment}
          handleClose={() => setAddShipment(false)}
        />
      )}
    </AppBar>
  );
}

export default Header;
