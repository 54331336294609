import axios from "axios";
import apiUrl from "Services/apiUrl";

async function initAppService() {
  try {
    const { data } = await axios.get(`${apiUrl}/api/init-frontend/`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { success: true, data };
  } catch (error) {
    return { success: false };
  }
}

export default initAppService;
