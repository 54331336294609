import { useState } from "react";
import { useTranslation } from "react-i18next";

import { TextValidator } from "react-material-ui-form-validator";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { InputAdornment, IconButton } from "@material-ui/core/";

// matchRegexp:^[A-Za-zА-Яа-я0-9_-s]{8,128}$
const validators = ["required", "minStringLength:6", "maxStringLength:50"];
const errors = [
  "Введите пароль",
  "Минимальная длина пароля - 6 символов",
  "Максимальная длина пароля - 30 символов",
];

const Password = ({ password, onChange, el_id, el_name, el_autocomp }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  return (
    <TextValidator
      autoComplete={el_autocomp ? el_autocomp : "current-password"}
      variant="outlined"
      validators={validators}
      errorMessages={errors}
      fullWidth
      name={el_name ? el_name : "password"}
      label={t("inputs.password.title")}
      type={show ? "text" : "password"}
      id={el_id ? el_id : "password"}
      value={password}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="Toggle password visibility"
              onClick={() => setShow(!show)}
            >
              {show ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Password;
