import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sdContainer: {
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
    // backgroundImage: `url(${theme.home.steps.backgroundImage})`,
    background: `${theme.palette.primary.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sdContent: {
    backgroundColor: "white",
    padding: "20px",
    textAlign: "center",
    width: "350px",
    boxShadow: "0px 1px 5px 1px rgb(249 249 249 / 61%);",
  },
}));

const ServerDownPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.sdContainer}>
      <div className={classes.sdContent}>
        <Typography variant="h4" style={{ fontSize: "30px" }}>
          Неизвестная ошибка
        </Typography>
        <Typography variant="body1" style={{ margin: "10px 0" }}>
          Попробуйте перезагрузить страницу или свяжитесь с нами, если ошибка
          повторится.
        </Typography>

        <Button
          disableElevation
          variant="outlined"
          color="primary"
          size="small"
          style={{ fontWeight: "bold", marginBottom: "10px" }}
          target="_blank"
          href="mailto:info@vezoo.tj"
        >
          Обратная связь
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          size="small"
          style={{ fontWeight: "bold" }}
          onClick={() => window.location.reload()}
        >
          Перезагрузить страницу
        </Button>
      </div>
    </div>
  );
};

export default ServerDownPage;
