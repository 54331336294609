import { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Downshift from "downshift";
import axios from "axios";
import { useTranslation } from "react-i18next";
import apiUrl from "Services/apiUrl";
import useDebounce from "Hooks/useDebounce";

import {
  TextField,
  Paper,
  MenuItem,
  Chip,
  InputAdornment,
} from "@material-ui/core";

import { StartIcon, FinishIcon, TranzitIcon } from "Components/SvgIcon";

const MultipleLocation = (props) => {
  const {
    placeholder,
    selected,
    setSelected,
    excludeList,
    type,
    label,
    error,
    icon,
  } = props;
  const { t, i18n } = useTranslation();

  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [inputValue, setInputValue] = useState("");

  const debouncedSearchTerm = useDebounce(searchText, 400);

  const services = {
    all: (search) => {
      return `${apiUrl}/api/locations/?search=${search}`;
    },
    cities: (search) => {
      return `${apiUrl}/api/locations/cities?search=${search}`;
    },
  };

  const clearSearch = () => {
    setLoading(false);
    setSearchText("");
    setInputValue("");
    // setSelected([]);
    setLocations(null);
    setNoResult(false);
  };

  const getIcon = (icon) => {
    return (
      <InputAdornment position="start">
        {icon === "start" && <StartIcon />}
        {icon === "finish" && <FinishIcon />}
        {icon === "tranzit" && <TranzitIcon />}
      </InputAdornment>
    );
  };

  const StartAdornment = () => {
    return (
      <>
        {icon && getIcon(icon)}
        {selected &&
          selected.map((item) => (
            <Chip
              key={item.id}
              tabIndex={-1}
              label={item.name}
              className={styles.chip}
              onDelete={handleDelete(item)}
            />
          ))}
      </>
    );
  };

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true);
      try {
        setNoResult(false);
        const url = services[type](debouncedSearchTerm);
        const { data } = await axios.get(url, {
          headers: {
            "Accept-Language": i18n.language,
          },
        });
        arrangeData(data.results);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (debouncedSearchTerm.length > 1) fetchLocations();
  }, [debouncedSearchTerm]);

  const arrangeData = (data) => {
    setLocations([]);
    if (excludeList && excludeList.length > 0 && data.length > 0) {
      data = data.filter(
        (item1) => !excludeList.some((item2) => item2.id === item1.id)
      );
    }
    if (data.length === 0) setNoResult(true);
    setLocations(data);
  };

  const Loading = () => {
    return (
      <Paper className={styles.paper} square>
        <div style={{ padding: "1em" }}>{t("search.field.loading")}</div>
      </Paper>
    );
  };

  const NoResults = () => {
    return (
      <Paper className={styles.paper} square>
        <div style={{ padding: "1em" }}>{t("search.field.no_result")}</div>
      </Paper>
    );
  };

  const inputChange = (event) => {
    const search = event.target.value.toLowerCase();
    setInputValue(event.target.value);
    if (!search) {
      clearSearch();
      return;
    }
    if (search.trim().length > 1) setLoading(true);
    setSearchText(search.trim());
  };

  function handleChange(item) {
    let newSelected = [...selected];
    if (newSelected.indexOf(item) === -1) {
      newSelected = [...newSelected, item];
    }
    clearSearch();
    setSelected(newSelected);
  }

  const handleDelete = (item) => () => {
    const newSelected = [...selected];
    newSelected.splice(newSelected.indexOf(item), 1);
    setSelected(newSelected);
  };

  function getTitle(location) {
    let array = [];
    goDeeper(location);
    function goDeeper(location) {
      if (location.parent !== null) {
        array.push(location.name);
        goDeeper(location.parent);
        return;
      }
      array.push(location.name);
    }
    return array.join(", ");
  }

  return (
    <Downshift
      onChange={handleChange}
      // initialSelectedItem={selected ? selected : null}
      // initialInputValue={selected ? getTitleForString(selected) : ''}
      inputValue={inputValue}
      itemToString={(item) => (item ? item.name : "")}
      selectedItem={selected ? selected : []}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        selectItem,
        setHighlightedIndex,
        reset,
      }) => (
        <div className={styles.container} tabIndex={-1}>
          <TextField
            {...getInputProps({
              label: label,
              error: error,
              placeholder: placeholder,
              fullWidth: true,
              variant: "outlined",
              tabIndex: -1,
              onChange: (event) => inputChange(event),
              onKeyDown: (event) => {
                if (event.key === "Enter") {
                  if (locations && locations.length === 1) {
                    selectItem(locations.pop());
                    return;
                  }
                  event.nativeEvent.preventDownshiftDefault = false;
                } else if (event.key === "Tab") {
                  if (locations && locations.length === 1) {
                    selectItem(locations.pop());
                    return;
                  } else if (locations && locations.length !== 0) {
                    setHighlightedIndex(0);
                    return;
                  }
                  event.nativeEvent.preventDownshiftDefault = false;
                }
                if (event.key === "Escape") {
                  selected && setSelected([]);
                  reset();
                  event.nativeEvent.preventDownshiftDefault = true;
                }
                if (
                  selected &&
                  selected.length &&
                  !inputValue.length &&
                  event.key === "Backspace"
                ) {
                  setSelected(selected.slice(0, selected.length - 1));
                }
              },
              InputProps: {
                startAdornment: <StartAdornment />,
              },
            })}
          />
          <div {...getMenuProps()}>
            {isOpen && (
              <>
                <Paper className={styles.paper} square>
                  {locations &&
                    locations.map((location, index) => (
                      <MenuItem
                        {...getItemProps({
                          index,
                          key: location.id,
                          component: "div",
                          item: location,
                          className: styles.menuItem,
                          style: {
                            backgroundColor:
                              highlightedIndex === index
                                ? "lightgray"
                                : "white",
                            fontWeight:
                              selectedItem === location ? "bold" : "normal",
                          },
                        })}
                      >
                        {getTitle(location)}
                      </MenuItem>
                    ))}
                </Paper>
                {inputValue && loading && <Loading />}
                {inputValue && noResult && <NoResults />}
              </>
            )}
          </div>
        </div>
      )}
    </Downshift>
  );
};

export default MultipleLocation;
