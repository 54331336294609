import { TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";

const validators = ["required", "minStringLength: 2", "maxStringLength: 45"];
const errors = [
  "Введите фамилию",
  "Фамилия введена неверно",
  "Фамилия введена неверно",
];

const LastName = ({ lastName, onChange }) => {
  const { t } = useTranslation();

  return (
    <TextValidator
      fullWidth
      autoComplete="lname"
      name="last_name"
      variant="outlined"
      id="last_name"
      label={t("inputs.last_name.title")}
      value={lastName}
      onChange={onChange}
      validators={validators}
      errorMessages={errors}
    />
  );
};

export default LastName;
