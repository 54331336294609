import { useState, lazy, useContext } from "react";

import {
  Redirect,
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";

import Layout from "./Components/Layout";
import AdminLayout from "./Components/AdminLayout";
import { WaitingComponent } from "Components/WaitingComponent";

import { MainProvider } from "Providers/MainProvider";
import { UserContext } from "Providers/UserProvider";

import { RouterLoadingOverlay } from "Containers/Overlay";

const LazyHome = lazy(() => import("Pages/Home/"));
const LazyJoin = lazy(() => import("Pages/Join/"));
const LazyLogin = lazy(() => import("Pages/Login/"));
const LazyRegistration = lazy(() => import("Pages/Registration/"));
const LazyConfirmEmail = lazy(() => import("Pages/ConfirmEmail/"));
const LazyProfileSingle = lazy(() => import("Pages/Profile/Single/"));
const LazyProfileMe = lazy(() => import("Pages/Profile/Me/"));
const LazyProfileEdit = lazy(() => import("Pages/Profile/Edit/"));
const LazyPasswordReset = lazy(() => import("Pages/PasswordReset/"));
const LazyShipmentList = lazy(() => import("Pages/Shipment/List/"));
const LazyDepartureList = lazy(() => import("Pages/Departure/List/"));
const LazyDealPage = lazy(() => import("Pages/Deal/"));
const LazyShipmentSingle = lazy(() => import("Pages/Shipment/Single/"));
const LazyDepartureSingle = lazy(() => import("Pages/Departure/Single/"));
const LazyFourZeroFour = lazy(() => import("Pages/FourZeroFour/"));
const LazyAdminUsers = lazy(() => import("Pages/Admin/Users/"));
const LazyAdminDepartures = lazy(() => import("Pages/Admin/Departures/"));
const LazyAdminShipments = lazy(() => import("Pages/Admin/Shipments/"));
const LazyAdminPolicy = lazy(() => import("Pages/Admin/Policy/"));
const LazyAdminReports = lazy(() => import("Pages/Admin/Reports/"));
const LazyAdminSuggestions = lazy(() => import("Pages/Admin/Suggestions"));
const LazyAdminTerms = lazy(() => import("Pages/Admin/Terms"));
const LazyPolicy = lazy(() => import("Pages/Policy/"));
const LazyTerms = lazy(() => import("Pages/Terms/"));
const LazyAbout = lazy(() => import("Pages/About/"));
const LazyServerDown = lazy(() => import("Pages/ServerDown/"));
const LazyAdminTranslations = lazy(() => import("Pages/Admin/Translations/"));
const LazyAdminSms = lazy(() => import("Pages/Admin/Sms/"));
const LazyAdminImagePred = lazy(() => import("Pages/Admin/ImageRecognition/"));

const NoAuthLayoutedRoute = ({ children }) => {
  //  только не авторизованные
  const { user } = useContext(UserContext);
  if (user) {
    return <Redirect to="/me" />;
  } else {
    return <Layout>{children}</Layout>;
  }
};

const AuthLayoutedRoute = ({ children }) => {
  //  только авторизованные
  const { user } = useContext(UserContext);
  if (user) {
    return <Layout>{children}</Layout>;
  } else {
    return <Redirect to="/join" />;
  }
};

const AnyLayoutedRoute = ({ children }) => {
  return <Layout>{children}</Layout>;
};

const AnyNoLayoutedRoute = ({ children }) => {
  return <>{children}</>;
};

const AdminLayoutedRoute = ({ children }) => {
  const { user } = useContext(UserContext);
  if (user && user.urp !== "x801") {
    return (
      <Layout>
        <AdminLayout>{children}</AdminLayout>
      </Layout>
    );
  } else if (user && user.urp === "x801") {
    return <Redirect to="/" />;
  } else {
    return <Redirect to="/join" />;
  }
};

export default function AppRouter() {
  const [refreshDepartures, makeRefreshDepartures] = useState(0);
  const [refreshShipments, makeRefreshShipments] = useState(0);

  return (
    <Router>
      <MainProvider
        value={{
          refreshDepartures,
          makeRefreshDepartures,
          refreshShipments,
          makeRefreshShipments,
        }}
      >
        <Switch>
          <Route
            path={[
              "/",
              "/policy",
              "/departures",
              "/departures/:url",
              "/shipments",
              "/shipments/:url",
              "/confirm/email/:hash",
              "/termsofuse",
              "/404",
              "/about",
            ]}
            exact
          >
            <AnyLayoutedRoute>
              <Switch>
                <Route
                  exact
                  path="/"
                  component={WaitingComponent(
                    LazyHome,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/policy"
                  component={WaitingComponent(
                    LazyPolicy,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/termsofuse"
                  component={WaitingComponent(
                    LazyTerms,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/departures"
                  component={WaitingComponent(
                    LazyDepartureList,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/departures/:url"
                  component={WaitingComponent(
                    LazyDepartureSingle,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/shipments"
                  component={WaitingComponent(
                    LazyShipmentList,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/shipments/:url"
                  component={WaitingComponent(
                    LazyShipmentSingle,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/confirm/email/:hash"
                  component={WaitingComponent(
                    LazyConfirmEmail,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/404"
                  component={WaitingComponent(
                    LazyFourZeroFour,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/about"
                  component={WaitingComponent(
                    LazyAbout,
                    <RouterLoadingOverlay />
                  )}
                />
              </Switch>
            </AnyLayoutedRoute>
          </Route>

          <Route
            path={["/deals/:url", "/profile/:username", "/me", "/settings"]}
            exact
          >
            <AuthLayoutedRoute>
              <Switch>
                <Route
                  exact
                  path="/deals/:url"
                  component={WaitingComponent(
                    LazyDealPage,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/profile/:username"
                  component={WaitingComponent(
                    LazyProfileSingle,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/me"
                  component={WaitingComponent(
                    LazyProfileMe,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/settings"
                  component={WaitingComponent(
                    LazyProfileEdit,
                    <RouterLoadingOverlay />
                  )}
                />
              </Switch>
            </AuthLayoutedRoute>
          </Route>
          <Route
            path={["/join", "/login", "/registration", "/passwordforgot"]}
            exact
          >
            <NoAuthLayoutedRoute>
              <Switch>
                <Route
                  exact
                  path="/join"
                  component={WaitingComponent(
                    LazyJoin,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/login"
                  component={WaitingComponent(
                    LazyLogin,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/registration"
                  component={WaitingComponent(
                    LazyRegistration,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/passwordforgot"
                  component={WaitingComponent(
                    LazyPasswordReset,
                    <RouterLoadingOverlay />
                  )}
                />
              </Switch>
            </NoAuthLayoutedRoute>
          </Route>
          <Route
            path={[
              "/va/users",
              "/va/departures",
              "/va/shipments",
              "/va/policy",
              "/va/reports",
              "/va/suggestions",
              "/va/termsofuse",
              "/va/translations",
              "/va/smss",
              "/va/imagerecognition",
            ]}
            exact
          >
            <AdminLayoutedRoute>
              <Switch>
                <Route
                  exact
                  path="/va/users"
                  component={WaitingComponent(
                    LazyAdminUsers,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/va/departures"
                  component={WaitingComponent(
                    LazyAdminDepartures,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/va/shipments"
                  component={WaitingComponent(
                    LazyAdminShipments,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/va/reports"
                  component={WaitingComponent(
                    LazyAdminReports,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/va/suggestions"
                  component={WaitingComponent(
                    LazyAdminSuggestions,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/va/policy"
                  component={WaitingComponent(
                    LazyAdminPolicy,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/va/termsofuse"
                  component={WaitingComponent(
                    LazyAdminTerms,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/va/translations"
                  component={WaitingComponent(
                    LazyAdminTranslations,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/va/smss"
                  component={WaitingComponent(
                    LazyAdminSms,
                    <RouterLoadingOverlay />
                  )}
                />
                <Route
                  exact
                  path="/va/imagerecognition"
                  component={WaitingComponent(
                    LazyAdminImagePred,
                    <RouterLoadingOverlay />
                  )}
                />
              </Switch>
            </AdminLayoutedRoute>
          </Route>

          <Route path={["/500"]} exact>
            <AnyNoLayoutedRoute>
              <Switch>
                <Route
                  exact
                  path="/500"
                  component={WaitingComponent(
                    LazyServerDown,
                    <RouterLoadingOverlay />
                  )}
                />
              </Switch>
            </AnyNoLayoutedRoute>
          </Route>

          <Route>
            <Redirect to="/404" />
          </Route>
        </Switch>
      </MainProvider>
    </Router>
  );
}
