import { TextValidator } from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useTranslation } from "react-i18next";
import { Currencies } from "Components/Selects";

const validators = ["isNumber"];

const errors = ["Только целые числа"];

const ReadyToPay = ({ price, onChange, currency, currencyOnChange }) => {
  const { t } = useTranslation();

  return (
    <TextValidator
      variant="outlined"
      id="price"
      placeholder={t("shipments.create.treaty_title")}
      name="price"
      value={price}
      onChange={onChange}
      validators={validators}
      errorMessages={errors}
      InputProps={{
        endAdornment: price && (
          <InputAdornment position="end">
            <Currencies
              currency={currency}
              handleChange={currencyOnChange}
              variant="standard"
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ReadyToPay;
