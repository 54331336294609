import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import apiUrl from "Services/apiUrl";

export default function useAuth({ setLoading }) {
  const [user, setUser] = useState(null);
  const [banned, setBanned] = useState(null);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    setLoading(true);
    const { success, data } = await initializeUser();
    if (success) {
      const { active, id } = data;
      if (!active) {
        logout();
        setBanned(id);
      }
      setUser(data);
    }
    setLoading(false);
  };

  const initializeUser = async () => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      let decoded = null;
      try {
        decoded = jwt_decode(access_token);
      } catch (error) {
        logout();
        return { success: false };
      }
      if (decoded.exp < Date.now() / 1000) {
        return { success: false };
      } else {
        let { success, data } = await getUserProfile({ access_token });
        return { success, data };
      }
    } else return { success: false };
  };

  async function getUserProfile({ access_token, lang = "ru" }) {
    try {
      const { data } = await axios.get(`${apiUrl}/api/profile/me`, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": lang,
          Authorization: `Bearer ${access_token}`,
        },
      });
      return { success: true, data };
    } catch (error) {
      if (!error.response) {
        return { success: false, status: 0 };
      }
      const { status } = error.response;
      if (status === 401) {
        logout();
      }
      return { success: false, status };
    }
  }

  function accessToken() {
    return localStorage.getItem("access_token");
  }

  function refreshToken() {
    return localStorage.getItem("refresh_token");
  }

  function setupTokens(data) {
    localStorage.setItem("access_token", data.access);
    localStorage.setItem("refresh_token", data.refresh);
  }

  function logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setUser(null);
  }

  async function getNewToken(refresh_token) {
    try {
      let res = await axios.post(`${apiUrl}/api/token/refresh/`, {
        refresh: refresh_token,
      });
      let data = res.data;
      localStorage.setItem("access_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      return data.access;
    } catch (error) {
      return false;
    }
  }

  const hasToken = () => {
    let access_token = localStorage.getItem("access_token");
    return access_token ? true : false;
  };

  const authenticateUser = (data) => {
    localStorage.setItem("access_token", data.access);
    localStorage.setItem("refresh_token", data.refresh);
  };

  async function login({ credits, lang = "ru" }) {
    try {
      const res = await axios.post(`${apiUrl}/api/auth/login`, credits, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": lang,
        },
      });
      const data = res.data;
      authenticateUser(data);
      getUserData();
      return { success: true };
    } catch (error) {
      setUser(null);
      return { success: false, error: error.response.data };
    }
  }

  async function registration({ credits, lang = "ru" }) {
    if (credits.type === "facebook") {
      try {
        const { data } = await axios.post(
          `${apiUrl}/api/registration/facebook/`,
          credits,
          {
            headers: {
              "Content-Type": "application/json",
              "Accept-Language": lang,
            },
          }
        );
        authenticateUser(data.tokens);
        return { success: true };
      } catch (error) {
        return { success: false, error: error.response.data };
      }
    } else {
      try {
        const res = await axios.post(
          `${apiUrl}/api/auth/registration`,
          credits,
          {
            headers: {
              "Content-Type": "application/json",
              "Accept-Language": lang,
            },
          }
        );
        const data = res.data;
        authenticateUser(data);
        getUserData();
        return { success: true };
      } catch (error) {
        return { success: false, error: error.response.data };
      }
    }
  }

  async function validateRegistration({ credits, lang = "ru" }) {
    try {
      await axios.post(`${apiUrl}/api/auth/registration`, credits, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": lang,
        },
      });
      return { success: true };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async function sendConfirmCode({ country_code, phone_number, lang = "ru" }) {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/sms/generate/`,
        { country_code, phone_number },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async function checkConfirmCode({
    country_code,
    phone_number,
    code,
    lang = "ru",
  }) {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/sms/check/`,
        { country_code, phone_number, code },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async function validatePhone({ values, lang = "ru" }) {
    try {
      await axios.post(`${apiUrl}/api/validate/phone`, values, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": lang,
        },
      });
      return { success: true };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async function isFacebookUserOld({ id, lang = "ru" }) {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/registration/facebook/is_existing/`,
        { id },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  return {
    user,
    setUser,
    banned,
    isFacebookUserOld,
    accessToken,
    refreshToken,
    hasToken,
    authenticateUser,
    login,
    logout,
    validateRegistration,
    registration,
    getNewToken,
    initializeUser,
    sendConfirmCode,
    checkConfirmCode,
    setupTokens,
    validatePhone,
    getUserData,
  };
}
