import { useState, useEffect, useContext } from "react";
import {
  Typography,
  Popover,
  IconButton,
  Badge,
  Button,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import styles from "./styles.module.css";
import { UserContext } from "Providers/UserProvider";
import NotificationList from "./NotificationList";
import { useTranslation } from "react-i18next";

import WebSocketInstance from "Services/WebSockets/Notifications";
import WSC from "Services/WebSockets/Notifications";

export default function NotificationService() {
  const [status, setStatus] = useState("connecting");

  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [rNotifications, setRNotifications] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const { i18n } = useTranslation();

  const { user } = useContext(UserContext);

  const parseNotifications = (notifications) => {
    const notificationsParsed = JSON.parse(notifications);
    const readedNotifications = notificationsParsed.filter(
      (notification) => notification.is_read === true
    );
    const unreadedNotifications = notificationsParsed.filter(
      (notification) => notification.is_read === false
    );
    setNotificationCount(unreadedNotifications.length);
    setNotifications(generateNotifications(unreadedNotifications, false));
    setRNotifications(generateNotifications(readedNotifications, true));
  };

  const refreshHandler = () => {
    WSC.fetchNotifications();
  };

  const resetReconnectionAttempt = () => {
    WSC.resetReconnectionAttempt({
      setStatus,
      parseNotifications,
      refreshHandler,
    });
  };

  useEffect(() => {
    if (user) WSC.start({ setStatus, parseNotifications, refreshHandler });
    return () => {
      if (user) WSC.finish();
    };
  }, [user]);

  const sendReadedHandler = (id) => {
    WSC.newReadMarker({ id });
  };

  const readManyHandler = (ids) => {
    ids.map((id) => WebSocketInstance.newReadMarker({ id }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function generateNotifications(notifications, readed) {
    let map = notifications.reduce((r, i) => {
      if (i.ntype === 1 || i.ntype === 2 || i.ntype === 3) {
        r["messages"] = r["messages"] || {};
        r["messages"][i.web_link] = r["messages"][i.web_link] || [];
        r["messages"][i.web_link].push(i);
      } else if (i.ntype === 4) {
        r["subDepartures"] = r["subDepartures"] || {};
        r["subDepartures"][i.web_link] = r["subDepartures"][i.web_link] || [];
        r["subDepartures"][i.web_link].push(i);
      } else if (i.ntype === 5) {
        r["subShipments"] = r["subShipments"] || {};
        r["subShipments"][i.web_link] = r["subShipments"][i.web_link] || [];
        r["subShipments"][i.web_link].push(i);
      } else if (i.ntype === 6) {
        r["ratings"] = r["ratings"] || [];
        let rating = { ...i, read: () => sendReadedHandler(i.id) };
        r["ratings"].push(rating);
      }
      return r;
    }, {});

    let messages = {};
    for (let key1 in map["messages"]) {
      messages[key1] = messages[key1] || {};
      messages[key1]["count"] = map["messages"][key1].length;
      messages[key1]["created"] = map["messages"][key1][0].created;
      messages[key1]["from"] = map["messages"][key1][0].from_user;
      let messagesIds = map["messages"][key1].map((message) => message.id);
      messages[key1]["read"] = () => readManyHandler(messagesIds);
    }
    map["messages"] = messages;

    let subDep = {};
    for (let key1 in map["subDepartures"]) {
      subDep[key1] = subDep[key1] || {};
      subDep[key1]["content"] = map["subDepartures"][key1][0][`content`];
      subDep[key1]["count"] = map["subDepartures"][key1].length;
      subDep[key1]["created"] = map["subDepartures"][key1][0].created;
      let subDepIds = map["subDepartures"][key1].map((sub) => sub.id);
      subDep[key1]["read"] = () => readManyHandler(subDepIds);
    }
    map["subDepartures"] = subDep;

    let subShi = {};
    for (let key1 in map["subShipments"]) {
      subShi[key1] = subShi[key1] || {};
      subShi[key1]["content"] = map["subShipments"][key1][0][`content`];
      subShi[key1]["count"] = map["subShipments"][key1].length;
      subShi[key1]["created"] = map["subShipments"][key1][0].created;
      let subShiIds = map["subShipments"][key1].map((sub) => sub.id);
      subShi[key1]["read"] = () => readManyHandler(subShiIds);
    }
    map["subShipments"] = subShi;

    let result = {};
    readed ? (result["readed"] = map) : (result["unreaded"] = map);
    return result;
  }

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Badge
          color="secondary"
          badgeContent={notifications ? notificationCount : 0}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={styles.mainContainer}>
          {notifications && status === "ready" && (
            <NotificationList
              notifications={{ ...notifications, ...rNotifications }}
              handleClose={handleClose}
            />
          )}
          {status === "connecting" && (
            <div className={styles.mainContent}>
              <Typography>Загрузка...</Typography>
            </div>
          )}
          {status === "reconnecting" && (
            <div className={styles.mainContent}>
              <Typography>Соединение прервано, переподключение...</Typography>
            </div>
          )}
          {status === "many_attempts" && (
            <div className={styles.mainContent}>
              <Typography>
                Не удалось установить соединение после нескольких попыток
                переподключения.
              </Typography>
              <Button
                variant="outlined"
                onClick={() => resetReconnectionAttempt()}
              >
                Повторить попытку
              </Button>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
}
