import { Fragment } from "react";
import "./layout.css";
import Header from "./Header";
import Footer from "./Footer";
import Content from "./Content";

export default function Layout({ children }) {
  return (
    <Fragment>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </Fragment>
  );
}
