import { useContext } from "react";
import axios from "axios";
import apiUrl from "Services/apiUrl";
import { UserContext } from "Providers/UserProvider";

export default function useProfile() {
  const { auth } = useContext(UserContext);

  async function editProfile({ values, lang = "ru" }) {
    try {
      const { data } = await axios.put(
        `${apiUrl}/api/profile/me/change`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken()}`,
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function editEmail({ values, lang = "ru" }) {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/profile/me/email`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken()}`,
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function getAnotherUserProfile({ username, lang = "ru" }) {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/web/profile/${username}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": lang,
            Authorization: `Bearer ${auth.accessToken()}`,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function getUserProfile({ lang = "ru" }) {
    try {
      const { data } = await axios.get(`${apiUrl}/api/profile/me`, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": lang,
          Authorization: `Bearer ${auth.accessToken()}`,
        },
      });
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function uploadAvatar({ values }) {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/profile/me/change/avatar`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.accessToken()}`,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function deleteAvatar() {
    try {
      const { data } = await axios.delete(
        `${apiUrl}/api/profile/me/change/avatar`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.accessToken()}`,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function updatePassword({ values, lang = "ru" }) {
    try {
      const { data } = await axios.put(
        `${apiUrl}/api/profile/me/change/password`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken()}`,
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function setupNewPassword({ values, lang = "ru" }) {
    try {
      const { data } = await axios.put(
        `${apiUrl}/api/profile/me/setup/password`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  return {
    getUserProfile,
    getAnotherUserProfile,
    editProfile,
    uploadAvatar,
    deleteAvatar,
    updatePassword,
    editEmail,
    setupNewPassword,
  };
}
