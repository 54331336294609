import { TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";

const validators = ["required", "minStringLength:2", "maxStringLength:35"];
const errors = ["Введите имя", "Имя введено неверно", "Имя введено неверно"];

const FirstName = ({ firstName, onChange }) => {
  const { t } = useTranslation();

  return (
    <TextValidator
      fullWidth
      autoComplete="fname"
      name="first_name"
      variant="outlined"
      id="first_name"
      label={t("inputs.first_name.title")}
      value={firstName}
      onChange={onChange}
      validators={validators}
      errorMessages={errors}
    />
  );
};

export default FirstName;
