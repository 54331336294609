import { useState, useEffect } from "react";
import styles from "./styles.module.css";
import DateFnsUtils from "@date-io/date-fns";
import { getHours, getMinutes, parseISO } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getDateLocale } from "Components/DateLocales/";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const DateTimePicker = ({
  value,
  changeValue,
  handleClose,
  minValue,
  maxValue,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [hour, setHour] = useState(12);
  const [minute, setMinute] = useState(0);

  const [hourStr, setHourStr] = useState("12");
  const [minuteStr, setMinuteStr] = useState("00");
  const [date, setDate] = useState(new Date());

  const saveDate = () => {
    let finalDate = new Date(date);
    finalDate.setHours(hour);
    finalDate.setMinutes(minute);
    changeValue(finalDate);
    handleClose();
  };

  useEffect(() => {
    setMinuteStr(minute.toString().padStart(2, "0"));
    setHourStr(hour.toString().padStart(2, "0"));
  }, [minute, hour]);

  useEffect(() => {
    if (!value && minValue) {
      value = minValue;
    }
    if (!value) {
      value = new Date();
    }
    if (typeof value === "string") {
      value = parseISO(value);
    }
    setHour(getHours(value));
    setMinute(getMinutes(value));
    setDate(value);
  }, []);

  return (
    <div className={styles.dateTimeContainer}>
      <div className={styles.calendarPartContainer}>
        <Typography variant="h5" align="center" style={{ marginBottom: "8px" }}>
          {t("dateTimePicker.date")}
        </Typography>
        <Calendar
          date={date}
          changeDate={setDate}
          mobile={mobile}
          minValue={minValue}
          maxValue={maxValue}
        />
      </div>
      <div className={styles.timePartContainer}>
        <Typography variant="h5" align="center" style={{ marginBottom: "8px" }}>
          {t("dateTimePicker.time")}
        </Typography>
        <TimeInput
          hour={hour}
          hourStr={hourStr}
          changeHour={(value) => setHour(value)}
          minuteStr={minuteStr}
          changeMinute={(value) => setMinute(value)}
        />
        <div className={styles.buttonsContainer}>
          <Button
            variant="contained"
            disableElevation
            style={{ marginRight: "8px" }}
            onClick={handleClose}
          >
            {t("dateTimePicker.cancel")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={saveDate}
          >
            {t("dateTimePicker.save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const Calendar = ({ date, changeDate, mobile, minValue, maxValue }) => {
  const { i18n } = useTranslation();

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={getDateLocale(i18n.language)}
    >
      <DatePicker
        autoOk
        variant="static"
        orientation={mobile ? "portrait" : "landscape"}
        openTo="date"
        disablePast
        ampm={false}
        value={date}
        onChange={changeDate}
        name="departure_time"
        locale={i18n.language}
        maxDate={maxValue && maxValue}
        minDate={minValue && minValue}
      />
    </MuiPickersUtilsProvider>
  );
};

const TimeInput = ({
  hour,
  hourStr,
  changeHour,
  minute,
  minuteStr,
  changeMinute,
}) => {
  function preChangeHour(event) {
    const value = parseInt(event.target.value);
    if (value > 23) {
      changeHour(0);
    } else if (value < 0) {
      changeHour(23);
    } else {
      changeHour(value);
    }
  }

  function preChangeMinute(event) {
    const value = parseInt(event.target.value);
    if (value > 59) {
      changeMinute(0);
    } else if (value < 0) {
      changeMinute(59);
    } else {
      changeMinute(value);
    }
  }

  return (
    <div className={styles.timeContainer}>
      <input
        value={hourStr}
        onChange={preChangeHour}
        type="number"
        className={styles.timeInput}
      />
      <span className={styles.timeDivider}>:</span>
      <input
        value={minuteStr}
        onChange={preChangeMinute}
        type="number"
        className={styles.timeInput}
      />
    </div>
  );
};

export default DateTimePicker;
