import { Suspense } from "react";

import AppRouter from "AppRouter";
import { globalStyles } from "SendlyTheme";

import i18n from "./i18n";

import MetaThemeProvider from "Providers/MetaThemeProvider";
import AuthProvider from "Providers/AuthProvider";
import { SuspenseLoader } from "Components/Loader";
import WelcomeModal from "Modals/Welcome";
import { useState } from "react";

function App({ data, welcomeOpenInit }) {
  const [welcomeOpen, setWelcomeOpen] = useState(
    welcomeOpenInit ? welcomeOpenInit : false
  );
  globalStyles();

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <MetaThemeProvider meta={data}>
        <AuthProvider>
          <AppRouter />
          <WelcomeModal
            open={welcomeOpen}
            handleClose={() => setWelcomeOpen(false)}
          />
        </AuthProvider>
      </MetaThemeProvider>
    </Suspense>
  );
}

export default App;
