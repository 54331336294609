import { useContext } from "react";

import { useHistory } from "react-router-dom";
import { formatDistance } from "date-fns";
import { getDateLocale } from "Components/DateLocales/";
import { useTranslation } from "react-i18next";
import { MainContext } from "Providers/MainProvider";

import styles from "./item.module.css";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CommuteIcon from "@material-ui/icons/Commute";
import CardTravelIcon from "@material-ui/icons/CardTravel";

const NotificationItem = ({ notification, handleClose, readed }) => {
  let icon, text, author, link, read;
  const { t, i18n } = useTranslation();

  if (notification.type === "message") {
    icon = <AccountCircleIcon fontSize="large" />;
    text = t("notifications.new_messages", {
      count: notification.count,
    });
    author = notification.from.first_name;
    link = `/deals/${notification.link}`;
    read = notification.read;
  } else if (notification.type === "departures") {
    icon = <CommuteIcon fontSize="large" />;
    text = t("notifications.new_departures", {
      route: notification.content,
      count: notification.count,
    });
    author = null;
    link = notification.link;
    read = notification.read;
  } else if (notification.type === "shipments") {
    icon = <CardTravelIcon fontSize="large" />;
    text = t("notifications.new_shipments", {
      route: notification.content,
      count: notification.count,
    });
    author = null;
    link = notification.link;
    read = notification.read;
  } else if (notification.type === "rating") {
    icon = <AccountCircleIcon fontSize="large" />;
    text = t("notifications.rating", {
      rating: notification.content,
    });
    author = notification.from_user.first_name;
    link = notification.link;
    read = notification.read;
  }

  const history = useHistory();
  const {
    refreshDepartures,
    makeRefreshDepartures,
    refreshShipments,
    makeRefreshShipments,
  } = useContext(MainContext);

  return (
    <div
      className={`${styles.container} ${!readed && styles.container_readed}`}
      onClick={() => {
        handleClose();
        read();
        history.push(link);
        if (notification.type === "departures") {
          makeRefreshDepartures(refreshDepartures + 1);
        }
        if (notification.type === "shipments") {
          makeRefreshShipments(refreshShipments + 1);
        }
      }}
    >
      <div className={styles.avatar}>{icon}</div>
      <div className={styles.content}>
        <div>
          {author && (
            <>
              <span className={styles.author}>{author}</span> -{" "}
            </>
          )}
          {text}
        </div>
        <div className={styles.time}>
          {formatDistance(Date.parse(notification.created), new Date(), {
            locale: getDateLocale(i18n.language),
            addSuffix: true,
          })}
          {!readed && (
            <div
              className={styles.hideButton}
              onClick={(e) => {
                e.stopPropagation();
                read();
              }}
            >
              {t("notifications.hide")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
