import { useEffect, useState } from "react";
import BaseModal from "Modals/Base";
import AddEditShipment from "Containers/AddEditShipment";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { Button, Grid, Typography } from "@material-ui/core";

const AddEditShipmentModal = ({
  open,
  handleClose,
  mode = "add",
  id = null,
}) => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState("");

  const [success, setSuccess] = useState(false);
  const [shipment, setShipment] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const onSuccess = (data) => {
    setLoading(false);
    setLoadingLabel("");
    setSuccess(true);
    setShipment(data);
  };

  const onSuccessEdit = (data) => {
    if (location.pathname === `/shipments/${data.url}`) {
      setLoading(true);
      window.location.reload();
    } else {
      history.replace(`/shipments/${data.url}`);
    }
  };

  const SuccessContainer = () => {
    let startLocation, finishLocation;

    useEffect(() => {
      if (shipment) {
        const routelocations = shipment.route.routelocations;
        if (routelocations) {
          startLocation = routelocations[0].location.id;
          finishLocation = routelocations[1].location.id;
        }
      }
    }, [shipment, i18n.language]);

    return (
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid item align="center">
          {mode === "add" && (
            <Typography variant="h5" className="marginBottom16">
              {t("shipments.create.success")}
            </Typography>
          )}
          {mode === "edit" && (
            <Typography variant="h5" className="marginBottom16">
              {t("shipments.edit.success")}
            </Typography>
          )}

          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              margin: "8px",
            }}
            onClick={() => {
              handleClose();
              history.push(
                `/departures?from=${startLocation}&to=${finishLocation}`
              );
            }}
          >
            {t("shipments.create.success_actions.find")}
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              margin: "8px",
            }}
            onClick={() => {
              handleClose();
              history.push(`/shipments/${shipment.url}`);
            }}
          >
            {t("shipments.create.success_actions.open")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <BaseModal
      title={
        mode === "add" ? t("shipments.create.title") : t("shipments.edit.title")
      }
      open={open}
      handleClose={handleClose}
      loading={loading}
      loadingLabel={loadingLabel}
      maxWidth="sm"
      disableBackdropClick={true}
    >
      {!success ? (
        <AddEditShipment
          loading={loading}
          setLoading={setLoading}
          setLoadingLabel={setLoadingLabel}
          onSuccess={onSuccess}
          onSuccessEdit={onSuccessEdit}
          mode={mode}
          id={id}
        />
      ) : (
        <SuccessContainer />
      )}
    </BaseModal>
  );
};

export default AddEditShipmentModal;
