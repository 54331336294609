import { useState, useEffect, useRef } from "react";
import styles from "./styles.module.css";
import axios from "axios";
import apiUrl from "Services/apiUrl";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

const TextIconSelect = ({
  tooltip,
  error,
  label,
  name,
  value,
  handleChange,
  url,
  data,
  variant,
}) => {
  const { t, i18n } = useTranslation();

  const [values, setValues] = useState([]);

  const [selectOpen, setSelectOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    async function getValues() {
      try {
        const { data } = await axios.get(`${apiUrl}/api/${url}`, {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": i18n.language,
          },
        });
        setValues(data);
      } catch (error) {
        const { data, status } = error.response;
      }
    }
    url ? getValues() : setValues(data);
  }, [i18n.language]);

  return (
    <Tooltip
      title={tooltip}
      placement="bottom"
      open={tooltipOpen}
      onClose={handleTooltipClose}
    >
      <span
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        onMouseDown={handleTooltipClose}
      >
        <FormControl
          fullWidth={Boolean(variant === "outlined")}
          variant={variant}
          error={Boolean(error)}
        >
          {variant === "outlined" && (
            <InputLabel ref={inputLabel}>{label}</InputLabel>
          )}
          <Select
            value={value}
            name={name}
            open={selectOpen}
            onOpen={handleSelectOpen}
            onClose={handleSelectClose}
            onChange={handleChange}
            labelWidth={labelWidth}
            disableUnderline={Boolean(variant === "standard")}
          >
            <MenuItem value="">
              <em>{t("inputs.no_selected")}</em>
            </MenuItem>
            {values &&
              values.map((value) => (
                <MenuItem value={value.id} key={value.id}>
                  {value.code && (
                    <span className={styles.iconMe}>{value.code}</span>
                  )}
                  {
                    <Typography
                      variant="inherit"
                      noWrap
                      style={{ verticalAlign: "middle" }}
                    >
                      {value.name}
                    </Typography>
                  }
                </MenuItem>
              ))}
          </Select>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </span>
    </Tooltip>
  );
};

export default TextIconSelect;
