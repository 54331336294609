import { makeStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  overlayContainer: {
    backgroundSize: "cover",
    background: `${theme.palette.primary.light}`,
    top: "0",
    bottom: "0",
    zIndex: "10000",
    overflow: "hidden",
    width: "100%",
    height: "100%",
    left: "0",
    transition: "300ms",
  },
  overlayContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  overlayLoader: {
    background: theme.home.hero.color,
  },
  overlayText: {
    color: theme.home.hero.color,
    fontSize: "1.3rem",
  },
}));

const LoadingOverlay = ({ text, position = "absolute" }) => {
  const classes = useStyles();

  return (
    <div className={classes.overlayContainer} style={{ position: position }}>
      <div className={classes.overlayContent}>
        <div className="animated-loader">
          <div className={classes.overlayLoader}></div>
          <div className={classes.overlayLoader}></div>
          <div className={classes.overlayLoader}></div>
          <div className={classes.overlayLoader}></div>
        </div>
        <div>
          <Typography
            component="h5"
            variant="h5"
            className={classes.overlayText}
          >
            {text}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const RouterLoadingOverlay = () => {
  const classes = useStyles();

  return (
    <div className={classes.overlayContainer} style={{ position: "absolute" }}>
      <div className={classes.overlayContent}>
        <div className="animated-loader">
          <div className={classes.overlayLoader}></div>
          <div className={classes.overlayLoader}></div>
          <div className={classes.overlayLoader}></div>
          <div className={classes.overlayLoader}></div>
        </div>
      </div>
    </div>
  );
};

const ErrorOverlay = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.overlayContainer} style={{ position: "absolute" }}>
      <div className={classes.overlayContent}>
        <div>
          <Typography
            component="h5"
            variant="h5"
            className={classes.overlayText}
          >
            {text}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export { LoadingOverlay, ErrorOverlay, RouterLoadingOverlay };
