import { wsUrl } from "Services/apiUrl";

class WebSocketClient {
  static instance = null;

  maxReconnectionAttempts = 5;
  reconnectionDelay = 1000;
  reconnectionAttempt = 0;
  sessionEnd = false;

  constructor() {
    this.socketRef = null;
  }

  static getInstance() {
    if (!WebSocketClient.instance)
      WebSocketClient.instance = new WebSocketClient();
    return WebSocketClient.instance;
  }

  start = ({ setStatus, parseNotifications, refreshHandler }) => {
    const token = localStorage.getItem("access_token");
    if (!token || token.length === 0) {
      setStatus("unauthorized");
      return;
    }
    const path = `${wsUrl}/ws/notifications/?token=${token}`;

    this.socketRef = new WebSocket(path);

    this.socketRef.onopen = () => {
      this.sendMessage({ command: "init_service" });
      this.fetchNotifications();
    };

    this.socketRef.onmessage = (e) => {
      const parsedData = JSON.parse(e.data);
      const command = parsedData.command;
      if (command === "notifications") {
        parseNotifications(parsedData.notifications);
        setStatus("ready");
      } else if (command === "refresh") {
        refreshHandler();
      }
    };

    this.socketRef.onerror = (e) => {
      console.log("ERROR");
    };

    this.socketRef.onclose = () => {
      if (this.sessionEnd) return;
      if (this.reconnectionAttempt > this.maxReconnectionAttempts) {
        setStatus("many_attempts");
        return;
      }
      setStatus("reconnecting");
      this.socketRef = null;
      setTimeout(() => {
        this.reconnectionAttempt += 1;
        this.start({
          setStatus,
          parseNotifications,
          refreshHandler,
        });
      }, this.reconnectionAttempt * this.reconnectionDelay);
    };
  };

  fetchNotifications = () => {
    this.sendMessage({ command: "fetch_notifications" });
  };

  finish = () => {
    this.sessionEnd = true;
    this.socketRef.close();
  };

  newReadMarker({ id }) {
    this.sendMessage({ command: "make_readed", id: id });
  }

  resetReconnectionAttempt = ({
    setStatus,
    parseNotifications,
    refreshHandler,
  }) => {
    this.reconnectionAttempt = 0;
    setStatus("connecting");
    this.start({ setStatus, parseNotifications, refreshHandler });
  };

  sendMessage(data) {
    try {
      this.socketRef.send(JSON.stringify({ ...data }));
    } catch (err) {
      console.log(err.message);
    }
  }
}

export default WebSocketClient.getInstance();
