import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

const primaryColor = "#1181CC";
// const secondaryColor = '#c1e5e2';
const secondaryColor = "#64ceff";
// const darkBackColor = '#00544a';
const darkBackColor = "#64ceff";
// const iconColor = "#25779c";
const iconColor = "#2681CC";

const titleColor = "#64ceff";

const defaultHeroImage =
  "https://images.unsplash.com/photo-1619075120066-02024cb853bd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1651&q=80";
const defaultHeroPosition = "center";
const defaultHeroColor = "white";
const whiteStepsImage = "plane_white.png";
const blackStepsImage = "plane_black.png";

const makeTheme = ({ data }) => {
  let theme = createTheme({
    palette: {
      primary: {
        main: data?.pallete?.primary || primaryColor,
      },
      secondary: {
        main: data?.pallete?.secondary || secondaryColor,
      },
      titleBackground: {
        main: data?.title?.backgroundColor || titleColor,
      },
      titleColor: {
        main: data?.title?.color || "white",
      },
      contentOverlayBackground: {
        main: data?.title?.backgroundColor || titleColor,
      },
      contentOverlayColor: {
        main: data?.title?.color || "white",
      },
      iconColor: {
        main: iconColor,
      },
      darkBack: {
        main: darkBackColor,
      },
      bigButtonColor: {
        main: "white",
      },
      tonalOffset: 0.2,
    },
    home: {
      hero: {
        backgroundImage: data?.home?.hero?.backgroundImage || defaultHeroImage,
        backgroundPosition: data?.home?.hero?.position || defaultHeroPosition,
        color: data?.home?.hero?.color || defaultHeroColor,
      },
      steps: {
        backgroundImage: data?.home?.steps?.backgroundImage || whiteStepsImage,
      },
    },
    // Изображения слайдера на главной и футера
    // Файлы находятся в папке public
    backgroundImages: {
      homeSlider: "/banner.jpg",
      footer: "/footer.png",
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1.1em",
          color: "white",
          backgroundColor: primaryColor,
        },
      },
      MuiButton: {
        contained: {
          boxShadow: null,
          "&:hover": {
            boxShadow: null,
          },
        },
      },
      MuiSelect: {
        selectMenu: {
          height: "1.1875em",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      },
    },
  });
  theme = responsiveFontSizes(theme);
  return theme;
};

export default makeTheme;
