/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { countries } from "Constants/phoneCountries.js";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return (
    <svg
      style={{
        width: "24px",
        height: "21px",
        marginRight: "6px",
        verticalAlign: "middle",
      }}
    >
      <use xlinkHref={`/countries.svg#${isoCode}`}></use>
    </svg>
  );
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function CountrySelect({ countryCode, onChange }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [value, setValue] = useState(null);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue.phone, true);
  };

  useEffect(() => {
    if (countryCode) {
      const country = countries.find(
        (country) => country.phone === countryCode
      );
      setValue(country);
    }
  }, [countryCode]);

  return (
    <Autocomplete
      id="country_code"
      name="country_code"
      options={countries}
      classes={{
        option: classes.option,
      }}
      fullWidth
      autoHighlight
      disableClearable
      value={value}
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      getOptionLabel={(option) => option[`name_${i18n.language}`]}
      renderOption={(option) => (
        <React.Fragment>
          <span>{countryToFlag(option.alpha2)}</span>
          {option[`name_${i18n.language}`]} ({option.alpha2}) +{option.phone}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("inputs.country.title")}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
