import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getDateLocale } from "Components/DateLocales/";
import DateFnsUtils from "@date-io/date-fns";
import { useTranslation } from "react-i18next";
import TodayIcon from "@material-ui/icons/Today";
import { InputAdornment } from "@material-ui/core";

export default function Birthday({ birthday, onChange }) {
  const { t, i18n } = useTranslation();

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={getDateLocale(i18n.language)}
    >
      <DatePicker
        fullWidth
        disableFuture
        openTo="year"
        views={["year", "month", "date"]}
        inputVariant="outlined"
        label={t("inputs.birthday.title")}
        format="dd.MM.yyyy"
        ampm={false}
        value={birthday}
        onChange={onChange}
        name="birth_date"
        okLabel={t("common.ok")}
        cancelLabel={t("common.cancel")}
        InputProps={{
          startAdornment: birthday ? (
            <InputAdornment position="start">
              <TodayIcon color="primary" />
            </InputAdornment>
          ) : null,
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
