export const countries = [
  {
    alpha2: "AD",
    alpha3: "AND",
    name_ru: "Андорра",
    name_tj: "Андорра",
    name_en: "Andorra",
    iso: 20,
    phone: "376",
  },
  {
    alpha2: "AE",
    alpha3: "ARE",
    name_ru: "Объединенные Арабские Эмираты",
    name_tj: "Объединенные Арабские Эмираты",
    name_en: "United Arab Emirates",
    iso: 784,
    phone: "971",
  },
  {
    alpha2: "AF",
    alpha3: "AFG",
    name_ru: "Афганистан",
    name_tj: "Афганистан",
    name_en: "Afghanistan",
    iso: 4,
    phone: "93",
  },
  {
    alpha2: "AL",
    alpha3: "ALB",
    name_ru: "Албания",
    name_tj: "Албания",
    name_en: "Albania",
    iso: 8,
    phone: "355",
  },
  {
    alpha2: "AM",
    alpha3: "ARM",
    name_ru: "Армения",
    name_tj: "Армения",
    name_en: "Armenia",
    iso: 51,
    phone: "374",
  },
  {
    alpha2: "AO",
    alpha3: "AGO",
    name_ru: "Ангола",
    name_tj: "Ангола",
    name_en: "Angola",
    iso: 24,
    phone: "244",
  },
  {
    alpha2: "AQ",
    alpha3: "ATA",
    name_ru: "Антарктида",
    name_tj: "Антарктида",
    name_en: "Antarctica",
    iso: 10,
    phone: "672",
  },
  {
    alpha2: "AR",
    alpha3: "ARG",
    name_ru: "Аргентина",
    name_tj: "Аргентина",
    name_en: "Argentina",
    iso: 32,
    phone: "54",
  },
  {
    alpha2: "AT",
    alpha3: "AUT",
    name_ru: "Австрия",
    name_tj: "Австрия",
    name_en: "Austria",
    iso: 40,
    phone: "43",
  },
  {
    alpha2: "AU",
    alpha3: "AUS",
    name_ru: "Австралия",
    name_tj: "Австралия",
    name_en: "Australia",
    iso: 36,
    phone: "61",
  },
  {
    alpha2: "AZ",
    alpha3: "AZE",
    name_ru: "Азербайджан",
    name_tj: "Азербайджан",
    name_en: "Azerbaijan",
    iso: 31,
    phone: "994",
  },
  {
    alpha2: "BA",
    alpha3: "BIH",
    name_ru: "Босния и Герцеговина",
    name_tj: "Босния и Герцеговина",
    name_en: "Bosnia and Herzegovina",
    iso: 70,
    phone: "387",
  },
  {
    alpha2: "BD",
    alpha3: "BGD",
    name_ru: "Бангладеш",
    name_tj: "Бангладеш",
    name_en: "Bangladesh",
    iso: 50,
    phone: "880",
  },
  {
    alpha2: "BE",
    alpha3: "BEL",
    name_ru: "Бельгия",
    name_tj: "Бельгия",
    name_en: "Belgium",
    iso: 56,
    phone: "32",
  },
  {
    alpha2: "BF",
    alpha3: "BFA",
    name_ru: "Буркина-Фасо",
    name_tj: "Буркина-Фасо",
    name_en: "Burkina Faso",
    iso: 854,
    phone: "226",
  },
  {
    alpha2: "BG",
    alpha3: "BGR",
    name_ru: "Болгария",
    name_tj: "Болгария",
    name_en: "Bulgaria",
    iso: 100,
    phone: "359",
  },
  {
    alpha2: "BH",
    alpha3: "BHR",
    name_ru: "Бахрейн",
    name_tj: "Бахрейн",
    name_en: "Bahrain",
    iso: 48,
    phone: "973",
  },
  {
    alpha2: "BM",
    alpha3: "BMU",
    name_ru: "Бермуды",
    name_tj: "Бермуды",
    name_en: "Bermuda",
    iso: 60,
    phone: "1-441",
  },
  {
    alpha2: "BO",
    alpha3: "BOL",
    name_ru: "Боливия",
    name_tj: "Боливия",
    name_en: "Bolivia",
    iso: 68,
    phone: "591",
  },
  {
    alpha2: "BR",
    alpha3: "BRA",
    name_ru: "Бразилия",
    name_tj: "Бразилия",
    name_en: "Brazil",
    iso: 76,
    phone: "55",
  },
  {
    alpha2: "BS",
    alpha3: "BHS",
    name_ru: "Багамы",
    name_tj: "Багамы",
    name_en: "Bahamas",
    iso: 44,
    phone: "1-242",
  },
  {
    alpha2: "BY",
    alpha3: "BLR",
    name_ru: "Беларусь",
    name_tj: "Беларусь",
    name_en: "Belarus",
    iso: 112,
    phone: "375",
  },
  {
    alpha2: "CA",
    alpha3: "CAN",
    name_ru: "Канада",
    name_tj: "Канада",
    name_en: "Canada",
    iso: 124,
    phone: "1",
  },
  {
    alpha2: "CF",
    alpha3: "CAF",
    name_ru: "Центрально-Африканская Республика",
    name_tj: "Центрально-Африканская Республика",
    name_en: "Central African Republic",
    iso: 140,
    phone: "236",
  },
  {
    alpha2: "CG",
    alpha3: "COG",
    name_ru: "Конго",
    name_tj: "Конго",
    name_en: "Congo",
    iso: 178,
    phone: "242",
  },
  {
    alpha2: "CH",
    alpha3: "CHE",
    name_ru: "Швейцария",
    name_tj: "Швейцария",
    name_en: "Switzerland",
    iso: 756,
    phone: "41",
  },
  {
    alpha2: "CI",
    alpha3: "CIV",
    name_ru: "Кот д'Ивуар",
    name_tj: "Кот д'Ивуар",
    name_en: "Cote d'Ivoire",
    iso: 384,
    phone: "225",
  },
  {
    alpha2: "CL",
    alpha3: "CHL",
    name_ru: "Чили",
    name_tj: "Чили",
    name_en: "Chile",
    iso: 152,
    phone: "56",
  },
  {
    alpha2: "CM",
    alpha3: "CMR",
    name_ru: "Камерун",
    name_tj: "Камерун",
    name_en: "Cameroon",
    iso: 120,
    phone: "237",
  },
  {
    alpha2: "CN",
    alpha3: "CHN",
    name_ru: "Китай",
    name_tj: "Китай",
    name_en: "China",
    iso: 156,
    phone: "86",
  },
  {
    alpha2: "CO",
    alpha3: "COL",
    name_ru: "Колумбия",
    name_tj: "Колумбия",
    name_en: "Colombia",
    iso: 170,
    phone: "57",
  },
  {
    alpha2: "CR",
    alpha3: "CRI",
    name_ru: "Коста-Рика",
    name_tj: "Коста-Рика",
    name_en: "Costa Rica",
    iso: 188,
    phone: "506",
  },
  {
    alpha2: "CU",
    alpha3: "CUB",
    name_ru: "Куба",
    name_tj: "Куба",
    name_en: "Cuba",
    iso: 192,
    phone: "53",
  },
  {
    alpha2: "CY",
    alpha3: "CYP",
    name_ru: "Кипр",
    name_tj: "Кипр",
    name_en: "Cyprus",
    iso: 196,
    phone: "357",
  },
  {
    alpha2: "CZ",
    alpha3: "CZE",
    name_ru: "Чешская Республика",
    name_tj: "Чешская Республика",
    name_en: "Czech Republic",
    iso: 203,
    phone: "420",
  },
  {
    alpha2: "DE",
    alpha3: "DEU",
    name_ru: "Германия",
    name_tj: "Германия",
    name_en: "Germany",
    iso: 276,
    phone: "49",
  },
  {
    alpha2: "DK",
    alpha3: "DNK",
    name_ru: "Дания",
    name_tj: "Дания",
    name_en: "Denmark",
    iso: 208,
    phone: "45",
  },
  {
    alpha2: "DO",
    alpha3: "DOM",
    name_ru: "Доминиканская Республика",
    name_tj: "Доминиканская Республика",
    name_en: "Dominican Republic",
    iso: 214,
    phone: "1-809",
  },
  {
    alpha2: "DZ",
    alpha3: "DZA",
    name_ru: "Алжир",
    name_tj: "Алжир",
    name_en: "Algeria",
    iso: 12,
    phone: "213",
  },
  {
    alpha2: "EC",
    alpha3: "ECU",
    name_ru: "Эквадор",
    name_tj: "Эквадор",
    name_en: "Ecuador",
    iso: 218,
    phone: "593",
  },
  {
    alpha2: "EE",
    alpha3: "EST",
    name_ru: "Эстония",
    name_tj: "Эстония",
    name_en: "Estonia",
    iso: 233,
    phone: "372",
  },
  {
    alpha2: "EG",
    alpha3: "EGY",
    name_ru: "Египет",
    name_tj: "Египет",
    name_en: "Egypt",
    iso: 818,
    phone: "20",
  },
  {
    alpha2: "ES",
    alpha3: "ESP",
    name_ru: "Испания",
    name_tj: "Испания",
    name_en: "Spain",
    iso: 724,
    phone: "34",
  },
  {
    alpha2: "ET",
    alpha3: "ETH",
    name_ru: "Эфиопия",
    name_tj: "Эфиопия",
    name_en: "Ethiopia",
    iso: 231,
    phone: "251",
  },
  {
    alpha2: "FI",
    alpha3: "FIN",
    name_ru: "Финляндия",
    name_tj: "Финляндия",
    name_en: "Finland",
    iso: 246,
    phone: "358",
  },
  {
    alpha2: "FJ",
    alpha3: "FJI",
    name_ru: "Фиджи",
    name_tj: "Фиджи",
    name_en: "Fiji",
    iso: 242,
    phone: "679",
  },
  {
    alpha2: "FO",
    alpha3: "FRO",
    name_ru: "Фарерские острова",
    name_tj: "Фарерские острова",
    name_en: "Faroe Islands",
    iso: 234,
    phone: "298",
  },
  {
    alpha2: "FR",
    alpha3: "FRA",
    name_ru: "Франция",
    name_tj: "Франция",
    name_en: "France",
    iso: 250,
    phone: "33",
  },
  {
    alpha2: "GA",
    alpha3: "GAB",
    name_ru: "Габон",
    name_tj: "Габон",
    name_en: "Gabon",
    iso: 266,
    phone: "241",
  },
  {
    alpha2: "GB",
    alpha3: "GBR",
    name_ru: "Соединенное Королевство",
    name_tj: "Соединенное Королевство",
    name_en: "United Kingdom",
    iso: 826,
    phone: "44",
  },
  {
    alpha2: "GE",
    alpha3: "GEO",
    name_ru: "Грузия",
    name_tj: "Грузия",
    name_en: "Georgia",
    iso: 268,
    phone: "995",
  },
  {
    alpha2: "GH",
    alpha3: "GHA",
    name_ru: "Гана",
    name_tj: "Гана",
    name_en: "Ghana",
    iso: 288,
    phone: "233",
  },
  {
    alpha2: "GI",
    alpha3: "GIB",
    name_ru: "Гибралтар",
    name_tj: "Гибралтар",
    name_en: "Gibraltar",
    iso: 292,
    phone: "350",
  },
  {
    alpha2: "GL",
    alpha3: "GRL",
    name_ru: "Гренландия",
    name_tj: "Гренландия",
    name_en: "Greenland",
    iso: 304,
    phone: "299",
  },
  {
    alpha2: "GM",
    alpha3: "GMB",
    name_ru: "Гамбия",
    name_tj: "Гамбия",
    name_en: "Gambia",
    iso: 270,
    phone: "220",
  },
  {
    alpha2: "GN",
    alpha3: "GIN",
    name_ru: "Гвинея",
    name_tj: "Гвинея",
    name_en: "Guinea",
    iso: 324,
    phone: "224",
  },
  {
    alpha2: "GP",
    alpha3: "GLP",
    name_ru: "Гваделупа",
    name_tj: "Гваделупа",
    name_en: "Guadeloupe",
    iso: 312,
    phone: "590",
  },
  {
    alpha2: "GR",
    alpha3: "GRC",
    name_ru: "Греция",
    name_tj: "Греция",
    name_en: "Greece",
    iso: 300,
    phone: "30",
  },
  {
    alpha2: "GT",
    alpha3: "GTM",
    name_ru: "Гватемала",
    name_tj: "Гватемала",
    name_en: "Guatemala",
    iso: 320,
    phone: "502",
  },
  {
    alpha2: "GY",
    alpha3: "GUY",
    name_ru: "Гайана",
    name_tj: "Гайана",
    name_en: "Guyana",
    iso: 328,
    phone: "592",
  },
  {
    alpha2: "HK",
    alpha3: "HKG",
    name_ru: "Гонконг",
    name_tj: "Гонконг",
    name_en: "Hong Kong",
    iso: 344,
    phone: "852",
  },
  {
    alpha2: "HN",
    alpha3: "HND",
    name_ru: "Гондурас",
    name_tj: "Гондурас",
    name_en: "Honduras",
    iso: 340,
    phone: "504",
  },
  {
    alpha2: "HR",
    alpha3: "HRV",
    name_ru: "Хорватия",
    name_tj: "Хорватия",
    name_en: "Croatia",
    iso: 191,
    phone: "385",
  },
  {
    alpha2: "HT",
    alpha3: "HTI",
    name_ru: "Гаити",
    name_tj: "Гаити",
    name_en: "Haiti",
    iso: 332,
    phone: "509",
  },
  {
    alpha2: "HU",
    alpha3: "HUN",
    name_ru: "Венгрия",
    name_tj: "Венгрия",
    name_en: "Hungary",
    iso: 348,
    phone: "36",
  },
  {
    alpha2: "ID",
    alpha3: "IDN",
    name_ru: "Индонезия",
    name_tj: "Индонезия",
    name_en: "Indonesia",
    iso: 360,
    phone: "62",
  },
  {
    alpha2: "IE",
    alpha3: "IRL",
    name_ru: "Ирландия",
    name_tj: "Ирландия",
    name_en: "Ireland",
    iso: 372,
    phone: "353",
  },
  {
    alpha2: "IL",
    alpha3: "ISR",
    name_ru: "Израиль",
    name_tj: "Израиль",
    name_en: "Israel",
    iso: 376,
    phone: "972",
  },
  {
    alpha2: "IN",
    alpha3: "IND",
    name_ru: "Индия",
    name_tj: "Индия",
    name_en: "India",
    iso: 356,
    phone: "91",
  },
  {
    alpha2: "IQ",
    alpha3: "IRQ",
    name_ru: "Ирак",
    name_tj: "Ирак",
    name_en: "Iraq",
    iso: 368,
    phone: "964",
  },
  {
    alpha2: "IR",
    alpha3: "IRN",
    name_ru: "Иран",
    name_tj: "Иран",
    name_en: "Iran",
    iso: 364,
    phone: "98",
  },
  {
    alpha2: "IS",
    alpha3: "ISL",
    name_ru: "Исландия",
    name_tj: "Исландия",
    name_en: "Iceland",
    iso: 352,
    phone: "354",
  },
  {
    alpha2: "IT",
    alpha3: "ITA",
    name_ru: "Италия",
    name_tj: "Италия",
    name_en: "Italy",
    iso: 380,
    phone: "39",
  },
  {
    alpha2: "JM",
    alpha3: "JAM",
    name_ru: "Ямайка",
    name_tj: "Ямайка",
    name_en: "Jamaica",
    iso: 388,
    phone: "1-876",
  },
  {
    alpha2: "JO",
    alpha3: "JOR",
    name_ru: "Иордания",
    name_tj: "Иордания",
    name_en: "Jordan",
    iso: 400,
    phone: "962",
  },
  {
    alpha2: "JP",
    alpha3: "JPN",
    name_ru: "Япония",
    name_tj: "Япония",
    name_en: "Japan",
    iso: 392,
    phone: "81",
  },
  {
    alpha2: "KE",
    alpha3: "KEN",
    name_ru: "Кения",
    name_tj: "Кения",
    name_en: "Kenya",
    iso: 404,
    phone: "254",
  },
  {
    alpha2: "KG",
    alpha3: "KGZ",
    name_ru: "Киргизия",
    name_tj: "Киргизия",
    name_en: "Kyrgyzstan",
    iso: 417,
    phone: "996",
  },
  {
    alpha2: "KH",
    alpha3: "KHM",
    name_ru: "Камбоджа",
    name_tj: "Камбоджа",
    name_en: "Cambodia",
    iso: 116,
    phone: "855",
  },
  {
    alpha2: "KR",
    alpha3: "KOR",
    name_ru: "Корея",
    name_tj: "Корея",
    name_en: "Korea",
    iso: 410,
    phone: "82",
  },
  {
    alpha2: "KW",
    alpha3: "KWT",
    name_ru: "Кувейт",
    name_tj: "Кувейт",
    name_en: "Kuwait",
    iso: 414,
    phone: "965",
  },
  {
    alpha2: "KZ",
    alpha3: "KAZ",
    name_ru: "Казахстан",
    name_tj: "Казахстан",
    name_en: "Kazakhstan",
    iso: 398,
    phone: "77",
  },
  {
    alpha2: "LA",
    alpha3: "LAO",
    name_ru: "Лаос",
    name_tj: "Лаос",
    name_en: "Lao",
    iso: 418,
    phone: "856",
  },
  {
    alpha2: "LB",
    alpha3: "LBN",
    name_ru: "Ливан",
    name_tj: "Ливан",
    name_en: "Lebanon",
    iso: 422,
    phone: "961",
  },
  {
    alpha2: "LI",
    alpha3: "LIE",
    name_ru: "Лихтенштейн",
    name_tj: "Лихтенштейн",
    name_en: "Liechtenstein",
    iso: 438,
    phone: "423",
  },
  {
    alpha2: "LK",
    alpha3: "LKA",
    name_ru: "Шри-Ланка",
    name_tj: "Шри-Ланка",
    name_en: "Sri Lanka",
    iso: 144,
    phone: "94",
  },
  {
    alpha2: "LR",
    alpha3: "LBR",
    name_ru: "Либерия",
    name_tj: "Либерия",
    name_en: "Liberia",
    iso: 430,
    phone: "231",
  },
  {
    alpha2: "LT",
    alpha3: "LTU",
    name_ru: "Литва",
    name_tj: "Литва",
    name_en: "Lithuania",
    iso: 440,
    phone: "370",
  },
  {
    alpha2: "LU",
    alpha3: "LUX",
    name_ru: "Люксембург",
    name_tj: "Люксембург",
    name_en: "Luxembourg",
    iso: 442,
    phone: "352",
  },
  {
    alpha2: "LV",
    alpha3: "LVA",
    name_ru: "Латвия",
    name_tj: "Латвия",
    name_en: "Latvia",
    iso: 428,
    phone: "371",
  },
  {
    alpha2: "LY",
    alpha3: "LBY",
    name_ru: "Ливия",
    name_tj: "Ливия",
    name_en: "Libyan",
    iso: 434,
    phone: "218",
  },
  {
    alpha2: "MA",
    alpha3: "MAR",
    name_ru: "Марокко",
    name_tj: "Марокко",
    name_en: "Morocco",
    iso: 504,
    phone: "212",
  },
  {
    alpha2: "MC",
    alpha3: "MCO",
    name_ru: "Монако",
    name_tj: "Монако",
    name_en: "Monaco",
    iso: 492,
    phone: "377",
  },
  {
    alpha2: "MD",
    alpha3: "MDA",
    name_ru: "Молдова",
    name_tj: "Молдова",
    name_en: "Moldova",
    iso: 498,
    phone: "373",
  },
  {
    alpha2: "ME",
    alpha3: "MNE",
    name_ru: "Черногория",
    name_tj: "Черногория",
    name_en: "Montenegro",
    iso: 499,
    phone: "382",
  },
  {
    alpha2: "MG",
    alpha3: "MDG",
    name_ru: "Мадагаскар",
    name_tj: "Мадагаскар",
    name_en: "Madagascar",
    iso: 450,
    phone: "261",
  },
  {
    alpha2: "MK",
    alpha3: "MKD",
    name_ru: "Македония",
    name_tj: "Македония",
    name_en: "Macedonia",
    iso: 807,
    phone: "389",
  },
  {
    alpha2: "ML",
    alpha3: "MLI",
    name_ru: "Мали",
    name_tj: "Мали",
    name_en: "Mali",
    iso: 466,
    phone: "223",
  },
  {
    alpha2: "MM",
    alpha3: "MMR",
    name_ru: "Мьянма",
    name_tj: "Мьянма",
    name_en: "Burma",
    iso: 104,
    phone: "95",
  },
  {
    alpha2: "MN",
    alpha3: "MNG",
    name_ru: "Монголия",
    name_tj: "Монголия",
    name_en: "Mongolia",
    iso: 496,
    phone: "976",
  },
  {
    alpha2: "MT",
    alpha3: "MLT",
    name_ru: "Мальта",
    name_tj: "Мальта",
    name_en: "Malta",
    iso: 470,
    phone: "356",
  },
  {
    alpha2: "MV",
    alpha3: "MDV",
    name_ru: "Мальдивы",
    name_tj: "Мальдивы",
    name_en: "Maldives",
    iso: 462,
    phone: "960",
  },
  {
    alpha2: "MX",
    alpha3: "MEX",
    name_ru: "Мексика",
    name_tj: "Мексика",
    name_en: "Mexico",
    iso: 484,
    phone: "52",
  },
  {
    alpha2: "MY",
    alpha3: "MYS",
    name_ru: "Малайзия",
    name_tj: "Малайзия",
    name_en: "Malaysia",
    iso: 458,
    phone: "60",
  },
  {
    alpha2: "MZ",
    alpha3: "MOZ",
    name_ru: "Мозамбик",
    name_tj: "Мозамбик",
    name_en: "Mozambique",
    iso: 508,
    phone: "258",
  },
  {
    alpha2: "NE",
    alpha3: "NER",
    name_ru: "Нигер",
    name_tj: "Нигер",
    name_en: "Niger",
    iso: 562,
    phone: "227",
  },
  {
    alpha2: "NG",
    alpha3: "NGA",
    name_ru: "Нигерия",
    name_tj: "Нигерия",
    name_en: "Nigeria",
    iso: 566,
    phone: "234",
  },
  {
    alpha2: "NI",
    alpha3: "NIC",
    name_ru: "Никарагуа",
    name_tj: "Никарагуа",
    name_en: "Nicaragua",
    iso: 558,
    phone: "505",
  },
  {
    alpha2: "NL",
    alpha3: "NLD",
    name_ru: "Нидерланды",
    name_tj: "Нидерланды",
    name_en: "Netherlands",
    iso: 528,
    phone: "31",
  },
  {
    alpha2: "NO",
    alpha3: "NOR",
    name_ru: "Норвегия",
    name_tj: "Норвегия",
    name_en: "Norway",
    iso: 578,
    phone: "47",
  },
  {
    alpha2: "NP",
    alpha3: "NPL",
    name_ru: "Непал",
    name_tj: "Непал",
    name_en: "Nepal",
    iso: 524,
    phone: "977",
  },
  {
    alpha2: "NZ",
    alpha3: "NZL",
    name_ru: "Новая Зеландия",
    name_tj: "Новая Зеландия",
    name_en: "New Zealand",
    iso: 554,
    phone: "64",
  },
  {
    alpha2: "OM",
    alpha3: "OMN",
    name_ru: "Оман",
    name_tj: "Оман",
    name_en: "Oman",
    iso: 512,
    phone: "968",
  },
  {
    alpha2: "PA",
    alpha3: "PAN",
    name_ru: "Панама",
    name_tj: "Панама",
    name_en: "Panama",
    iso: 591,
    phone: "507",
  },
  {
    alpha2: "PE",
    alpha3: "PER",
    name_ru: "Перу",
    name_tj: "Перу",
    name_en: "Peru",
    iso: 604,
    phone: "51",
  },
  {
    alpha2: "PG",
    alpha3: "PNG",
    name_ru: "Папуа-Новая Гвинея",
    name_tj: "Папуа-Новая Гвинея",
    name_en: "Papua New Guinea",
    iso: 598,
    phone: "675",
  },
  {
    alpha2: "PH",
    alpha3: "PHL",
    name_ru: "Филиппины",
    name_tj: "Филиппины",
    name_en: "Philippines",
    iso: 608,
    phone: "63",
  },
  {
    alpha2: "PK",
    alpha3: "PAK",
    name_ru: "Пакистан",
    name_tj: "Пакистан",
    name_en: "Pakistan",
    iso: 586,
    phone: "92",
  },
  {
    alpha2: "PL",
    alpha3: "POL",
    name_ru: "Польша",
    name_tj: "Польша",
    name_en: "Poland",
    iso: 616,
    phone: "48",
  },
  {
    alpha2: "PR",
    alpha3: "PRI",
    name_ru: "Пуэрто-Рико",
    name_tj: "Пуэрто-Рико",
    name_en: "Puerto Rico",
    iso: 630,
    phone: "1",
  },
  {
    alpha2: "PT",
    alpha3: "PRT",
    name_ru: "Португалия",
    name_tj: "Португалия",
    name_en: "Portugal",
    iso: 620,
    phone: "351",
  },
  {
    alpha2: "PY",
    alpha3: "PRY",
    name_ru: "Парагвай",
    name_tj: "Парагвай",
    name_en: "Paraguay",
    iso: 600,
    phone: "595",
  },
  {
    alpha2: "QA",
    alpha3: "QAT",
    name_ru: "Катар",
    name_tj: "Катар",
    name_en: "Qatar",
    iso: 634,
    phone: "974",
  },
  {
    alpha2: "RO",
    alpha3: "ROU",
    name_ru: "Румыния",
    name_tj: "Румыния",
    name_en: "Romania",
    iso: 642,
    phone: "40",
  },
  {
    alpha2: "RS",
    alpha3: "SRB",
    name_ru: "Сербия",
    name_tj: "Сербия",
    name_en: "Serbia",
    iso: 688,
    phone: "381",
  },
  {
    alpha2: "RU",
    alpha3: "RUS",
    name_ru: "Россия",
    name_tj: "Россия",
    name_en: "Russian Federation",
    iso: 643,
    phone: "7",
  },
  {
    alpha2: "RW",
    alpha3: "RWA",
    name_ru: "Руанда",
    name_tj: "Руанда",
    name_en: "Rwanda",
    iso: 646,
    phone: "250",
  },
  {
    alpha2: "SA",
    alpha3: "SAU",
    name_ru: "Саудовская Аравия",
    name_tj: "Саудовская Аравия",
    name_en: "Saudi Arabia",
    iso: 682,
    phone: "966",
  },
  {
    alpha2: "SC",
    alpha3: "SYC",
    name_ru: "Сейшелы",
    name_tj: "Сейшелы",
    name_en: "Seychelles",
    iso: 690,
    phone: "248",
  },
  {
    alpha2: "SD",
    alpha3: "SDN",
    name_ru: "Судан",
    name_tj: "Судан",
    name_en: "Sudan",
    iso: 729,
    phone: "249",
  },
  {
    alpha2: "SE",
    alpha3: "SWE",
    name_ru: "Швеция",
    name_tj: "Швеция",
    name_en: "Sweden",
    iso: 752,
    phone: "46",
  },
  {
    alpha2: "SG",
    alpha3: "SGP",
    name_ru: "Сингапур",
    name_tj: "Сингапур",
    name_en: "Singapore",
    iso: 702,
    phone: "65",
  },
  {
    alpha2: "SI",
    alpha3: "SVN",
    name_ru: "Словения",
    name_tj: "Словения",
    name_en: "Slovenia",
    iso: 705,
    phone: "386",
  },
  {
    alpha2: "SK",
    alpha3: "SVK",
    name_ru: "Словакия",
    name_tj: "Словакия",
    name_en: "Slovakia",
    iso: 703,
    phone: "421",
  },
  {
    alpha2: "SM",
    alpha3: "SMR",
    name_ru: "Сан-Марино",
    name_tj: "Сан-Марино",
    name_en: "San Marino",
    iso: 674,
    phone: "378",
  },
  {
    alpha2: "SN",
    alpha3: "SEN",
    name_ru: "Сенегал",
    name_tj: "Сенегал",
    name_en: "Senegal",
    iso: 686,
    phone: "221",
  },
  {
    alpha2: "SO",
    alpha3: "SOM",
    name_ru: "Сомали",
    name_tj: "Сомали",
    name_en: "Somalia",
    iso: 706,
    phone: "252",
  },
  {
    alpha2: "SS",
    alpha3: "SSD",
    name_ru: "Южный Судан",
    name_tj: "Южный Судан",
    name_en: "South Sudan",
    iso: 728,
    phone: "211",
  },
  {
    alpha2: "SY",
    alpha3: "SYR",
    name_ru: "Сирия",
    name_tj: "Сирия",
    name_en: "Syria",
    iso: 760,
    phone: "963",
  },
  {
    alpha2: "TH",
    alpha3: "THA",
    name_ru: "Таиланд",
    name_tj: "Таиланд",
    name_en: "Thailand",
    iso: 764,
    phone: "66",
  },
  {
    alpha2: "TJ",
    alpha3: "TJK",
    name_ru: "Таджикистан",
    name_tj: "Таджикистан",
    name_en: "Tajikistan",
    iso: 762,
    phone: "992",
  },
  {
    alpha2: "TM",
    alpha3: "TKM",
    name_ru: "Туркмения",
    name_tj: "Туркмения",
    name_en: "Turkmenistan",
    iso: 795,
    phone: "993",
  },
  {
    alpha2: "TN",
    alpha3: "TUN",
    name_ru: "Тунис",
    name_tj: "Тунис",
    name_en: "Tunisia",
    iso: 788,
    phone: "216",
  },
  {
    alpha2: "TR",
    alpha3: "TUR",
    name_ru: "Турция",
    name_tj: "Турция",
    name_en: "Turkey",
    iso: 792,
    phone: "90",
  },
  {
    alpha2: "TV",
    alpha3: "TUV",
    name_ru: "Тувалу",
    name_tj: "Тувалу",
    name_en: "Tuvalu",
    iso: 798,
    phone: "688",
  },
  {
    alpha2: "TW",
    alpha3: "TWN",
    name_ru: "Тайвань",
    name_tj: "Тайвань",
    name_en: "Taiwan",
    iso: 158,
    phone: "886",
  },
  {
    alpha2: "TZ",
    alpha3: "TZA",
    name_ru: "Танзания",
    name_tj: "Танзания",
    name_en: "Tanzania",
    iso: 834,
    phone: "255",
  },
  {
    alpha2: "UA",
    alpha3: "UKR",
    name_ru: "Украина",
    name_tj: "Украина",
    name_en: "Ukraine",
    iso: 804,
    phone: "380",
  },
  {
    alpha2: "UG",
    alpha3: "UGA",
    name_ru: "Уганда",
    name_tj: "Уганда",
    name_en: "Uganda",
    iso: 800,
    phone: "256",
  },
  {
    alpha2: "US",
    alpha3: "USA",
    name_ru: "Соединенные Штаты",
    name_tj: "Соединенные Штаты",
    name_en: "United States",
    iso: 840,
    phone: "1",
  },
  {
    alpha2: "UY",
    alpha3: "URY",
    name_ru: "Уругвай",
    name_tj: "Уругвай",
    name_en: "Uruguay",
    iso: 858,
    phone: "598",
  },
  {
    alpha2: "UZ",
    alpha3: "UZB",
    name_ru: "Узбекистан",
    name_tj: "Узбекистан",
    name_en: "Uzbekistan",
    iso: 860,
    phone: "998",
  },
  {
    alpha2: "VE",
    alpha3: "VEN",
    name_ru: "Венесуэла",
    name_tj: "Венесуэла",
    name_en: "Venezuela",
    iso: 862,
    phone: "58",
  },
  {
    alpha2: "VN",
    alpha3: "VNM",
    name_ru: "Вьетнам",
    name_tj: "Вьетнам",
    name_en: "Vietnam",
    iso: 704,
    phone: "84",
  },
  {
    alpha2: "WS",
    alpha3: "WSM",
    name_ru: "Самоа",
    name_tj: "Самоа",
    name_en: "Samoa",
    iso: 882,
    phone: "685",
  },
  {
    alpha2: "YE",
    alpha3: "YEM",
    name_ru: "Йемен",
    name_tj: "Йемен",
    name_en: "Yemen",
    iso: 887,
    phone: "967",
  },
  {
    alpha2: "ZA",
    alpha3: "ZAF",
    name_ru: "Южная Африка",
    name_tj: "Южная Африка",
    name_en: "South Africa",
    iso: 710,
    phone: "27",
  },
  {
    alpha2: "ZM",
    alpha3: "ZMB",
    name_ru: "Замбия",
    name_tj: "Замбия",
    name_en: "Zambia",
    iso: 894,
    phone: "260",
  },
  {
    alpha2: "ZW",
    alpha3: "ZWE",
    name_ru: "Зимбабве",
    name_tj: "Зимбабве",
    name_en: "Zimbabwe",
    iso: 716,
    phone: "263",
  },
];
