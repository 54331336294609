import { useState } from "react";
import { useTranslation } from "react-i18next";

import { FormControlLabel, Checkbox } from "@material-ui/core";

import TermsDialog from "Modals/ServiceTermsOfUse";

const ServiceTerms = ({ value, setValue, initTermsOpen = false }) => {
  const { t } = useTranslation();
  const [termsOpen, setTermsOpen] = useState(initTermsOpen);

  return (
    <>
      <FormControlLabel
        required
        control={
          <Checkbox
            value="agreed"
            color="primary"
            checked={value}
            onClick={() => {
              setValue(!value);
            }}
          />
        }
        label={
          <span>
            {t("auth.terms.checkbox_start")}
            <label
              onClick={() => setTermsOpen(true)}
              style={{
                textDecoration: "underline",
                color: "#0000FF",
                cursor: "pointer",
              }}
            >
              {t("auth.terms.checkbox_end")}
            </label>
          </span>
        }
      />
      <TermsDialog
        open={termsOpen}
        handleClose={() => setTermsOpen(false)}
        setValue={setValue}
      />
    </>
  );
};

export default ServiceTerms;
