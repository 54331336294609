import { TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function Email({ email, changeEmail, helperText, confrimed }) {
  const { t } = useTranslation();

  const validators = ["isEmail", "required"];
  const errors = [
    t("inputs.email.errors.invalid"),
    t("inputs.email.errors.request"),
  ];

  return (
    <TextValidator
      fullWidth
      id="email"
      name="email"
      label="Email"
      placeholder={t("inputs.email.placeholder")}
      helperText={helperText}
      variant="outlined"
      value={email}
      onChange={changeEmail}
      validators={validators}
      errorMessages={errors}
      autoComplete="email"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {confrimed ? (
              <div
                style={{
                  width: "100%",
                  padding: "12px",
                  backgroundColor: "#14d44f",
                  color: "white",
                }}
              >
                {t("inputs.email.confirmed")}
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  padding: "12px",
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                {t("inputs.email.noconfirmed")}
              </div>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}
