import { createContext } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import makeTheme from "./makeTheme";

const defaultSocials = {
  facebook: "https://facebook.com/",
  instagram: "https://instagram.com/",
  telegram: "https://telegram.com/",
  whatsapp: "https://whatsapp.com/",
};

const defaultMobileApps = {
  google_play: "https://play.google.com/",
  app_store: "https://apple.com/",
};

const defaultTheme = {
  pallete: { primary: "#2681CC", secondary: "#64ceff" },
  fonts: { primary: "Segoi Ui", secondary: "Tahoma" },
  title: { color: "#ffffff", backgroundColor: "#2681CC" },
};

export const MetaContext = createContext();
export const MetaProvider = MetaContext.Provider;

const MetaThemeProvider = ({ meta, children }) => {
  const theme = makeTheme({ data: meta.theme ? meta.theme : defaultTheme });

  return (
    <MetaProvider
      value={{
        socials: meta.socials ? meta.socials : defaultSocials,
        mobileApps: meta.mobile_apps ? meta.mobile_apps : defaultMobileApps,
        feedback: meta.feedback ? meta.feedback : []
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MetaProvider>
  );
};

export default MetaThemeProvider;
