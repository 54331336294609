import { TextValidator } from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useTranslation } from "react-i18next";

const validators = ["required"];

const errors = ["Введите номер телефона"];

const PhoneNumber = ({ countryCode, phoneNumber, onChange }) => {
  const { t } = useTranslation();

  return (
    <TextValidator
      fullWidth
      variant="outlined"
      id="phone_number"
      key="inputPhoneForPasswordReset"
      label={t("inputs.phone_number.title")}
      name="phone_number"
      autoComplete="phone_number"
      value={phoneNumber}
      onChange={onChange}
      validators={validators}
      errorMessages={errors}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">+{countryCode}</InputAdornment>
        ),
      }}
    />
  );
};

export default PhoneNumber;
