import { useState } from "react";

import { UserProvider } from "Providers/UserProvider";

import useAuth from "Hooks/useAuth";
import BannedPage from "Pages/Banned";

import { RouterLoadingOverlay } from "Containers/Overlay";

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const { user, setUser, banned, ...auth } = useAuth({ setLoading });

  const [reloadApp, setReloadApp] = useState(0);

  if (banned) return <BannedPage userId={banned} />;
  if (loading) return <RouterLoadingOverlay />;

  return (
    <UserProvider
      value={{
        user,
        setUser,
        auth,
        reloadApp,
        setReloadApp,
      }}
    >
      {children}
    </UserProvider>
  );
};

export default AuthProvider;
