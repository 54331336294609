import { useRef } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { useTranslation } from "react-i18next";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

import { Button } from "@material-ui/core";
import styles from "./upload.module.css";

const UploadImages = ({ images, addImage, removeImage }) => {
  const hiddenFileInput = useRef(null);
  const { t } = useTranslation();

  const handleImage = (event) => {
    const selectedImages = event.target.files;
    Array.from(selectedImages).forEach((image) => addImage(image));
    event.target.value = null;
  };

  const handleAddClick = () => {
    hiddenFileInput.current.click();
  };

  const Previews =
    images &&
    Array.isArray(images) &&
    images.map((file) => (
      <li key={file.name} className={styles.li}>
        <img
          key={file.name}
          src={URL.createObjectURL(file)}
          className={styles.image}
        />
        <span
          className={styles.remove}
          onClick={(event) => {
            return removeImage(event, file);
          }}
        >
          <CancelIcon />
        </span>
      </li>
    ));

  return (
    <div>
      <div className={styles.content}>
        <ul className={styles.ul}>{Previews}</ul>

        <input
          type="file"
          ref={hiddenFileInput}
          style={{ display: "none" }}
          onChange={handleImage}
          multiple
        />
        <Button
          startIcon={<PhotoCamera />}
          variant="contained"
          color="primary"
          style={{
            boxShadow: "none",
            margin: "8px",
          }}
          onClick={handleAddClick}
        >
          {t("shipments.create.photo.add")}
        </Button>
        <span>{t("shipments.create.photo.action_tip")}</span>
      </div>
    </div>
  );
};

export default UploadImages;
