import React from 'react';
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import LinkRef from "Components/LinkRef";
import styles from "./footer.module.css";
import AddEditDepartureModal from "Modals/AddEditDeparture";
import AddEditShipmentModal from "Modals/AddEditShipment";
import {
  Typography,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { UserContext } from "Providers/UserProvider";
import { MetaContext } from "Providers/MetaThemeProvider";

import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "block",
    position: "relative",
    width: "100%",
    background: theme.palette.primary.main,
    boxShadow: "0px -2px 2px 0px rgba(0,0,0,0.40)",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      minHeight: "480px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "380px",
    },
  },
  footerBackground: {
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundImage: `url(${theme.backgroundImages.footer})`,
  },
  footerContainer: {
    paddingTop: theme.spacing(4),
  },
  footerMenuContainer: {
    minHeight: "300px",
  },
  footerCopyrightContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  flexContainer: {
    display: "flex",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      float: "right",
    },
  },
  listIText: {
    whiteSpace: "nowrap",
    color: "white",
    textDecoration: "none",
    fontSize: "14px",
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { socials, mobileApps, feedback } = useContext(MetaContext);
  const history = useHistory();
  const [addDeparture, setAddDeparture] = useState(false);
  const [addShipment, setAddShipment] = useState(false);

  const getSocialLink = (socialName) => {
    return (
      socials.find((social) => social.name === socialName)?.value || socialName
    );
  };

  const getMobAppLink = (mobileAppName) => {
    return (
      mobileApps.find((mobileApp) => mobileApp.name === mobileAppName)?.value ||
      mobileAppName
    );
  };

  const getFeedback = () => {
    if (getDomain() === "tj") {
      return {
        phoneNumber: feedback?.find((fb) => fb.name === "phoneNumber_tj"),
        email: feedback?.find((fb) => fb.name === "email_tj"),
      };
    } else if (getDomain() === "uz") {
      return {
        phoneNumber: feedback?.find((fb) => fb.name === "phoneNumber_uz"),
        email: feedback?.find((fb) => fb.name === "email_uz"),
      };    
    }  
  };

  const getDomain = () => {
    if (
      window.location.hostname === "vezoo.tj" ||
      window.location.hostname === "www.vezoo.tj"
    ) {
      return "tj";
    } else if (
      window.location.hostname === "vezoo.uz" ||
      window.location.hostname === "www.vezoo.uz"
    ) {
      return "uz";
    } else {
      return "tj";
    }
  };

  return (
  
    <footer className={classes.footer}>
      <div className={classes.footerBackground}>
        <Container className={classes.footerContainer}>
          <Grid container spacing={2} className={classes.footerMenuContainer}>
            <Grid item container xs={12} alignItems="center" spacing={3}>
              <Grid item md={4} xs={12}>
                <Typography variant="h3" align="center" gutterBottom>
                  VEZOO
                </Typography>
              </Grid>
              <Grid item md={3} xs={12} style={{ textAlign: "center" }}>
                <a
                  href={getSocialLink("facebook")}
                  style={{ marginRight: "4px" }}
                >
                  <img
                    src="/icons/socials/facebook.png"
                    alt="fb social"
                    className={styles.socialIcon}
                  />
                </a>
                <a
                  href={getSocialLink("instagram")}
                  style={{ marginRight: "4px" }}
                >
                  <img
                    src="/icons/socials/instagram.png"
                    alt="vk social"
                    className={styles.socialIcon}
                  />
                </a>
                <a
                  href={getSocialLink("telegram")}
                  style={{ marginRight: "4px" }}
                >
                  <img
                    src="/icons/socials/telegram.png"
                    alt="fb social"
                    className={styles.socialIcon}
                  />
                </a>
                <a
                  href={getSocialLink("whatsapp")}
                  style={{ marginRight: "4px" }}
                >
                  <img
                    src="/icons/socials/whatsapp.png"
                    alt="vk social"
                    className={styles.socialIcon}
                  />
                </a>
                <a
                  href={getSocialLink("youtube")}
                  style={{ marginRight: "4px" }}
                >
                  <img
                    src="/icons/socials/youtube.png"
                    alt="youtube social"
                    className={styles.socialIcon}
                  />
                </a>
              </Grid>
              <Grid item md={5} xs={12}>
                <div style={{ display: "flex", textAlign: "center" }}>
                  <a href={getMobAppLink("app_store")}>
                    <img
                      alt="app-store"
                      src="/icons/mob-apps/app-store.svg"
                      width="180px"
                      height="60px"
                      style={{
                        border: "2px solid white",
                        marginRight: "4px",
                        backgroundColor: "#353535",
                        userSelect: "none",
                      }}
                    />
                  </a>
                  <a href={getMobAppLink("google_play")}>
                    <img
                      alt="google-play"
                      src="/icons/mob-apps/google-play.svg"
                      height="60px"
                      width="180px"
                      style={{
                        border: "2px solid white",
                        backgroundColor: "#353535",
                        userSelect: "none",
                      }}
                    />
                  </a>
                </div>
              </Grid>
            </Grid>
            <div style={{ borderBottom: "1px solid white", width: "100%" }} />
            <Grid item md={4} xs={12}>
              <ul className={styles.menu_ul}>
                <li>
                  <RouterLink to="/departures" className={styles.menu_link}>
                    {t("footer.menu.m1")}
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/shipments" className={styles.menu_link}>
                    {t("footer.menu.m2")}
                  </RouterLink>
                </li>
                <li
                  className={styles.menu_link}
                  onClick={() =>
                    user ? setAddDeparture(true) : history.push("/join")
                  }
                >
                  {t("footer.menu.m3")}
                </li>
                <li
                  className={styles.menu_link}
                  onClick={() =>
                    user ? setAddShipment(true) : history.push("/join")
                  }
                >
                  {t("footer.menu.m4")}
                </li>
              </ul>
            </Grid>
            <Grid item md={4} xs={12}>
              <ul className={styles.menu_ul}>
                <li
                  className={styles.menu_link}
                  onClick={() =>
                    user
                      ? history.push(`/profile/${user.username}`)
                      : history.push("/join")
                  }
                >
                  {t("footer.menu.m5")}
                </li>
                <li
                  className={styles.menu_link}
                  onClick={() =>
                    user
                      ? history.push(`/me?tab=departures`)
                      : history.push("/join")
                  }
                >
                  {t("footer.menu.m6")}
                </li>
                <li
                  className={styles.menu_link}
                  onClick={() =>
                    user
                      ? history.push(`/me?tab=shipments`)
                      : history.push("/join")
                  }
                >
                  {t("footer.menu.m7")}
                </li>
                <li
                  className={styles.menu_link}
                  onClick={() =>
                    user ? history.push(`/settings`) : history.push("/join")
                  }
                >
                  {t("footer.menu.m8")}
                </li>
              </ul>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">{t("footer.feedback.title")}</Typography>
              <Typography variant="body1">
                {t("footer.feedback.phoneNumber")}:{" "}
                <b>
                  {<a className={styles.menu_link}
                    href={`tel:${getFeedback()["phoneNumber"].value}`}>
                    {getFeedback()["phoneNumber"].value}
                  </a> }
                </b>
              </Typography>
              <Typography variant="body1">
                {t("footer.feedback.email")}:{" "}
                <b>
                  { <a
                    className={styles.menu_link}
                    href={`mailto:${getFeedback()["email"].value}`}
                  >
                    {getFeedback()["email"].value}
                  </a> } 
                </b>
              </Typography>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline" }}
                component={LinkRef}
                to={{ pathname: `/about`, target: "_blank" }}
                className={styles.sub_menu_item}
              >
                {t("footer.feedback.about")}
              </Typography>
            </Grid>
          </Grid>
          <div style={{ borderBottom: "1px solid white", width: "100%" }} />
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.footerCopyrightContainer}
          >
            <Grid item md={7} xs={12} className={styles.copyright}>
              Copyright © {new Date().getFullYear()} {t("footer.rights")}
            </Grid>
            <Grid item md={5} xs={12}>
              <List className={classes.flexContainer}>
                <ListItem
                  component={LinkRef}
                  to={{ pathname: `/termsofuse`, target: "_blank" }}
                >
                  <ListItemText
                    disableTypography
                    primary={t("footer.terms")}
                    className={styles.sub_menu_item}
                  />
                </ListItem>
                <ListItem
                  component={LinkRef}
                  to={{ pathname: `/policy`, target: "_blank" }}
                >
                  <ListItemText
                    disableTypography
                    primary={t("footer.politics")}
                    className={styles.sub_menu_item}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>
      {addDeparture && (
        <AddEditDepartureModal
          open={addDeparture}
          handleClose={() => setAddDeparture(false)}
        />
      )}
      {addShipment && (
        <AddEditShipmentModal
          open={addShipment}
          handleClose={() => setAddShipment(false)}
        />
      )}
    </footer>

  );
}

