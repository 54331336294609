const BannedPage = ({ userId }) => {
  return (
    <div>
      <div>{userId}</div>
      <div>Вы заблокированы!</div>
    </div>
  );
};

export default BannedPage;
