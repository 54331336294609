import { Fragment } from "react";
import styles from "./admin-layout.module.css";

import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: "220px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.titleColor.main,
  },
  sidebarText: {
    color: theme.palette.titleColor.main,
    fontWeight: "600",
  },
}));

const AdminSidebar = () => {
  const classes = useStyles();

  const mainMenuItems = [
    {
      link: "/va/users",
      name: "Пользователи",
      Icon: <InboxIcon color="inherit" />,
    },
    {
      link: "/va/departures",
      name: "Поездки",
      Icon: <InboxIcon color="inherit" />,
    },
    {
      link: "/va/shipments",
      name: "Посылки",
      Icon: <InboxIcon color="inherit" />,
    },
    {
      link: "/va/reports",
      name: "Жалобы",
      Icon: <InboxIcon color="inherit" />,
    },
    {
      link: "/va/suggestions",
      name: "Предложения",
      Icon: <InboxIcon color="inherit" />,
    },
    {
      link: "/va/translations",
      name: "Перевод фронтенда",
      Icon: <InboxIcon color="inherit" />,
    },
    {
      link: "/va/smss",
      name: "SMS",
      Icon: <InboxIcon color="inherit" />,
    },
    {
      link: "/va/imagerecognition",
      name: "Image Recognition",
      Icon: <InboxIcon color="inherit" />,
    },
  ];

  const secondaryMenuItems = [
    {
      link: "/va/policy",
      name: "Политика конфиденциальности",
    },
    {
      link: "/va/termsofuse",
      name: "Условия использования",
    },
  ];

  return (
    <div className={classes.sidebar} variant="permanent">
      <div className={styles.sidebarContainer}>
        <List>
          {mainMenuItems.map((item, index) => (
            <ListItem button key={item.link} component={Link} to={item.link}>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" className={classes.sidebarText}>
                    {item.name}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {secondaryMenuItems.map((item, index) => (
            <ListItem button key={item.link} component={Link} to={item.link}>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" className={classes.sidebarText}>
                    {item.name}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default function AdminLayout({ children }) {
  return (
    <Fragment>
      <div className={styles.adminContainer}>
        <AdminSidebar />
        <div className={styles.adminContent}>{children}</div>
      </div>
    </Fragment>
  );
}
