import { TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";

const validators = ["required"];
const errors = ["Введите первое сообщение"];
//!!!ПЕРЕВОД

export default function FirstMessage({ firstMessage, changeFirstMessage }) {
  const { t } = useTranslation();

  return (
    <TextValidator
      fullWidth
      multiline
      autoFocus
      id="firstMessage"
      name="firstMessage"
      label={t("shipments.choose.text_field.label")}
      placeholder={t("shipments.choose.text_field.placeholder")}
      helperText={t("shipments.choose.text_field.helperText")}
      style={{ margin: 8 }}
      rows={5}
      rowsMax={5}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      value={firstMessage}
      onChange={changeFirstMessage}
      validators={validators}
      errorMessages={errors}
    />
  );
}
