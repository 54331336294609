import { useTranslation } from "react-i18next";

import BaseSelect from "./Base";
import TextIconSelect from "./TextIcon";
import DependedSelect from "./Depended";
import SingleLocation from "./SingleLocation";
import MultipleLocation from "./MultipleLocation";
import Country from "./Country";

const TransportTypes = ({ error, transport_type, handleChange }) => {
  const { t } = useTranslation();

  return (
    <BaseSelect
      tooltip={t("selects.transport_type.tip")}
      error={error}
      label={t("selects.transport_type.label")}
      name="transport_type"
      value={transport_type}
      handleChange={handleChange}
      url="transports/types/"
    />
  );
};

const WeightTypes = ({ error, max_weight, handleChange }) => {
  const { t } = useTranslation();

  return (
    <BaseSelect
      tooltip={t("selects.max_weight.tip")}
      error={error}
      label={t("selects.max_weight.label")}
      name="max_weight"
      value={max_weight}
      handleChange={handleChange}
      url="weights/types/"
    />
  );
};

const FreightTypes = ({ error, freight_type, handleChange }) => {
  const { t } = useTranslation();

  return (
    <BaseSelect
      tooltip={t("selects.freight_type.tip")}
      error={error}
      label={t("selects.freight_type.label")}
      name="freight_type"
      value={freight_type}
      handleChange={handleChange}
      url="freights/types/"
    />
  );
};

const LuggageTypes = ({
  error,
  luggage_type,
  handleChange,
  nullLuggageType,
  transport_type = null,
}) => {
  const { t } = useTranslation();

  return (
    <DependedSelect
      tooltip={t("selects.luggage_type.tip")}
      dependedTooltip={t("selects.luggage_type.tt_first_tip")}
      error={error}
      label={t("selects.luggage_type.label")}
      name="luggage_type"
      value={luggage_type}
      handleChange={handleChange}
      url="luggages/types/"
      clearValue={nullLuggageType}
      dependedValue={transport_type}
    />
  );
};

const Languages = ({ error, language, handleChange }) => {
  const { t } = useTranslation();

  const languages = [
    {
      id: "ru",
      name: "Русский язык",
      icon: "/icons/countries/russia.svg",
    },
    {
      id: "en",
      name: "English language",
      icon: "/icons/countries/united-kingdom.svg",
    },
    {
      id: "tj",
      name: "Забони Тоҷикӣ",
      icon: "/icons/countries/tajikistan.svg",
    },
  ];

  return (
    <BaseSelect
      tooltip={t("selects.lang.tip")}
      error={error}
      label={t("selects.lang.label")}
      name="language"
      value={language}
      handleChange={handleChange}
      data={languages}
    />
  );
};

const Sexes = ({ error, sex, handleChange }) => {
  const { t } = useTranslation();

  return (
    <BaseSelect
      tooltip={t("selects.sex.tip")}
      error={error}
      label={t("selects.sex.label")}
      name="sex"
      value={sex}
      handleChange={handleChange}
      url="genders/"
    />
  );
};
//standard outlined
const Currencies = ({ error, currency, handleChange, variant }) => {
  const { t } = useTranslation();

  return (
    <TextIconSelect
      variant={variant}
      tooltip={t("selects.currency.tip")}
      error={error}
      label={t("selects.currency.label")}
      name="currency"
      value={currency}
      handleChange={handleChange}
      url="currencies/"
    />
  );
};

const Rights = ({ urp, onChange }) => {
  const data = [
    {
      name: "Пользователь",
      id: "x801",
    },
    {
      name: "Модератор",
      id: "m120",
    },
    {
      name: "Администратор",
      id: "a919",
    },
  ];

  return (
    <BaseSelect
      defaultValue="x801"
      showEmpty={false}
      name="urp"
      value={urp}
      handleChange={onChange}
      data={data}
      tooltip="Выберите роль"
    />
  );
};

export {
  Rights,
  TransportTypes,
  WeightTypes,
  FreightTypes,
  LuggageTypes,
  Languages,
  Sexes,
  Currencies,
  SingleLocation,
  MultipleLocation,
  Country,
};
