import { useState, useEffect, useRef } from "react";
import axios from "axios";
import apiUrl from "Services/apiUrl";
import { useTranslation } from "react-i18next";
import { SvgMenuIcon } from "Components/SvgIcon";
import {
  Tooltip,
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

const DependedSelect = ({
  tooltip,
  dependedTooltip,
  error,
  label,
  name,
  value,
  clearValue,
  dependedValue,
  handleChange,
  url,
  data,
}) => {
  const { t, i18n } = useTranslation();

  const [values, setValues] = useState([]);

  const [selectOpen, setSelectOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    async function getValues() {
      try {
        const { data } = await axios.get(`${apiUrl}/api/${url}`, {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": i18n.language,
          },
        });
        setValues(data);
      } catch (error) {
        const { data, status } = error.response;
      }
    }
    url ? getValues() : setValues(data);
  }, [i18n.language]);

  useEffect(() => {
    if (!dependedValue) {
      clearValue();
    }
  }, [dependedValue]);

  function getFilteredValues(value) {
    if (value && value.transporttypes.length > 0 && dependedValue) {
      return value.transporttypes.includes(dependedValue);
    }
    return false;
  }

  return (
    <Tooltip
      title={dependedValue ? tooltip : dependedTooltip}
      placement="bottom"
      open={tooltipOpen}
      onClose={handleTooltipClose}
    >
      <span
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        onMouseDown={handleTooltipClose}
      >
        <FormControl
          fullWidth
          variant="outlined"
          error={Boolean(error)}
          disabled={!dependedValue}
        >
          <InputLabel ref={inputLabel}>{label}</InputLabel>
          <Select
            value={value}
            name={name}
            open={selectOpen}
            onOpen={handleSelectOpen}
            onClose={handleSelectClose}
            onChange={handleChange}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>{t("inputs.no_selected")}</em>
            </MenuItem>
            {values &&
              values
                .filter((value) => getFilteredValues(value))
                .map((value) => (
                  <MenuItem value={value.id} key={value.id}>
                    {value.icon && (
                      <SvgMenuIcon
                        icon={value.icon}
                        staticIcon={Boolean(data)}
                      />
                    )}
                    {
                      <Typography
                        variant="inherit"
                        noWrap
                        style={{ verticalAlign: "middle" }}
                      >
                        {value.name}
                      </Typography>
                    }
                  </MenuItem>
                ))}
          </Select>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </span>
    </Tooltip>
  );
};

export default DependedSelect;
