import { forwardRef } from "react";
import { NavLink } from "react-router-dom";

const LinkRef = forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

const InlineLinkRef = forwardRef((props, ref) => (
  <div ref={ref} style={{ display: "inline" }}>
    <NavLink {...props} />
  </div>
));

export { LinkRef as default, InlineLinkRef };
