import { useState, useEffect } from "react";
import BaseModal from "Modals/Base";
import { useTranslation } from "react-i18next";
import useSettings from "Hooks/useSettings";
import MDEditor from "@uiw/react-md-editor";
// ПЕРЕВОД

const TermsDialog = ({ open, handleClose, setValue }) => {
  const { t, i18n } = useTranslation();
  const [terms, setTerms] = useState({
    name: "terms",
    value: "",
    modified: new Date(),
  });
  const [loading, setLoading] = useState(false);
  const settingsServices = useSettings();

  const handleAgree = () => {
    setValue(true);
    handleClose();
  };

  const handleDisagree = () => {
    setValue(false);
    handleClose();
  };

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      settingsServices
        .getSimpleSetting({
          name: "terms",
          lang: i18n.language,
        })
        .then(({ success, data, error, status }) => {
          if (success) {
            setTerms(data);
          } else {
            console.log(error);
          }
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <BaseModal
      maxWidth="sm"
      open={open}
      handleClose={handleClose}
      title={t("terms.title")}
      actionButton={handleDisagree}
      actionButtonLabel={t("terms.disagree")}
      actionButton2={handleAgree}
      actionButtonLabel2={t("terms.agree")}
      loading={loading}
    >
      <div style={{height: "400px"}}>
      <MDEditor.Markdown source={terms.value} /></div>
    </BaseModal>
  );
};

export default TermsDialog;
