import { useContext } from "react";
import axios from "axios";
import apiUrl from "Services/apiUrl";
import { UserContext } from "Providers/UserProvider";

export default function useShipments() {
  const { auth } = useContext(UserContext);

  async function editShipment({ id, values, lang = "ru" }) {
    try {
      const { data } = await axios.patch(
        `${apiUrl}/api/shipments/${id}`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.accessToken()}`,
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function addShipment({ values, lang = "ru" }) {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/web/shipments/`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.accessToken()}`,
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function getShipments({
    start = null,
    finish = null,
    page = 1,
    lang = "ru",
  }) {
    try {
      const url =
        start && finish
          ? `${apiUrl}/api/shipments?start_location=${start}&finish_location=${finish}&page=${page}`
          : `${apiUrl}/api/shipments/?page=${page}`;

      const { data } = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": lang,
        },
      });
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  function getStartQuery(start) {
    let startQuery = ``;
    start &&
      start.map((item) => {
        startQuery += `&start=${item}`;
      });
    return startQuery;
  }

  async function getUserShipments({
    page = 1,
    active = null,
    start = null,
    finish = null,
    departure = null,
    lang = "ru",
  }) {
    try {
      const activeQuery = active ? `&active=${active}` : ``;
      const startQuery = start ? getStartQuery(start) : ``;
      const finishQuery = finish ? `&finish=${finish}` : ``;
      const values = departure ? { departure } : null;

      const url = `${apiUrl}/api/shipments/me/?page=${page}${activeQuery}${startQuery}${finishQuery}`;
      const { data } = await axios.post(url, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken()}`,
          "Accept-Language": lang,
        },
      });
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function getShipment({ id, lang = "ru" }) {
    try {
      const { data } = await axios.get(`${apiUrl}/api/shipments/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": lang,
        },
      });
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function getShipmentByUrl({ url, lang = "ru" }) {
    try {
      const { data } = await axios.get(`${apiUrl}/api/web/shipments/${url}`, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": lang,
        },
      });
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }
  async function checkShipmentEditable({ id, lang = "ru" }) {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/shipments/${id}/editable`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken()}`,
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function getLocation({ id, lang = "ru" }) {
    try {
      const { data } = await axios.get(`${apiUrl}/api/locations/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": lang,
        },
      });
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function deleteShipment({ id, lang = "ru" }) {
    try {
      const { data } = await axios.delete(`${apiUrl}/api/shipments/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken()}`,
          "Accept-Language": lang,
        },
      });
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function deleteShipmentImage({ id, photo_id, lang = "ru" }) {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/shipments/${id}/images/remove`,
        { photo_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken()}`,
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  async function addShipmentImage({ id, values, lang = "ru" }) {
    try {
      const { data } = await axios.patch(
        `${apiUrl}/api/shipments/${id}/images/`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken()}`,
            "Accept-Language": lang,
          },
        }
      );
      return { success: true, data };
    } catch (error) {
      const { data, status } = error.response;
      return { success: false, data, status };
    }
  }

  return {
    addShipment,
    getShipments,
    getUserShipments,
    getShipment,
    getShipmentByUrl,
    getLocation,
    checkShipmentEditable,
    editShipment,
    deleteShipment,
    deleteShipmentImage,
    addShipmentImage,
  };
}
