import { enUS, ru } from "date-fns/locale";

const locales = {
  ru: ru,
  tj: ru,
  en: enUS,
};

export const getDateLocale = (lang = "ru") => {
  return locales[lang];
};
