import { useEffect, useState, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { MainContext } from "Providers/MainProvider";
import { format, compareAsc, parseISO } from "date-fns";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import {
  Typography,
  Button,
  Container,
  TextField,
  Grid,
  InputAdornment,
} from "@material-ui/core";

import { SingleLocation, MultipleLocation } from "Components/Selects/";
import { ValidatorForm } from "react-material-ui-form-validator";
import TodayIcon from "@material-ui/icons/Today";
import EventIcon from "@material-ui/icons/Event";
import DateTimePickerModal from "Modals/DateTime";
import { TransportTypes, WeightTypes, LuggageTypes } from "Components/Selects";
import useDepartures from "Hooks/useDepartures";

import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getDateLocale } from "Components/DateLocales/";

export default function AddEditDeparture({
  depStartLocation,
  depFinishLocation,
  shipmentDeadline,
  loading,
  setLoading,
  setLoadingLabel,
  onSuccess,
  onSuccessEdit,
  mode,
  id,
  choose,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { refreshDepartures, makeRefreshDepartures } = useContext(MainContext);
  const [tab, setTab] = useState(1);
  const departureServices = useDepartures();

  const { t, i18n } = useTranslation();

  const [startLocation, setStartLocation] = useState(null);
  const [finishLocation, setFinishLocation] = useState(null);
  const [transitPoints, setTransitPoints] = useState([]);

  const [values, setValues] = useState({
    transport_type: "",
    max_weight: "",
    luggage_type: "",
    description: "",
    departure_time: "",
    arrival_time: "",
  });

  const [errors, setErrors] = useState({
    transport_type: false,
    max_weight: false,
    luggage_type: false,
    departure_time: null,
    arrival_time: null,
  });

  const [startLocationError, setStartLocationError] = useState(null);
  const [finishLocationError, setFinishLocationError] = useState(null);

  useEffect(() => {
    if (depStartLocation && depFinishLocation) {
      setStartLocation(depStartLocation);
      setFinishLocation(depFinishLocation);
    }
  }, [depStartLocation, depFinishLocation]);

  const [departureTimeModalOpen, setDepartureTimeModalOpen] = useState(false);
  const [arrivalTimeModalOpen, setArrivalTimeModalOpen] = useState(false);

  function dateTimeArrChange(value) {
    setValues((oldValues) => ({
      ...oldValues,
      arrival_time: value,
    }));
    setErrors((oldErrors) => ({
      ...oldErrors,
      arrival_time: false,
    }));

    if (
      values.departure_time &&
      compareAsc(value, values.departure_time) === -1
    ) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        arrival_time: t("departures.create.errors.arr_gt_dep_times"),
      }));
    }
    if (
      shipmentDeadline &&
      compareAsc(parseISO(shipmentDeadline), value) === -1
    ) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        arrival_time: t("departures.create.errors.arr_gt_deadline_time"),
      }));
    }
  }

  function dateTimeDepChange(value) {
    setValues((oldValues) => ({
      ...oldValues,
      departure_time: value,
    }));
    setErrors((oldErrors) => ({
      ...oldErrors,
      departure_time: false,
    }));
  }

  function handleChange(event) {
    event.persist();
    setErrors((oldErrors) => ({
      ...oldErrors,
      [event.target.name]: null,
    }));
    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  }

  function nullLuggageType() {
    setValues((oldValues) => ({
      ...oldValues,
      luggage_type: "",
    }));
  }

  const createDeparture = () => {
    setLoading(true);
    setLoadingLabel(t("common.loaders.add_departure"));

    let locations = [
      { id: startLocation.id, order: 1 },
      { id: finishLocation.id, order: null },
    ];
    if (transitPoints) {
      locations = [
        ...locations,
        ...transitPoints.map((item, index) => ({
          id: item.id,
          order: index + 2,
        })),
      ];
    }
    let send_values = {
      ...values,
      locations: locations,
    };
    departureServices
      .addDeparture({ values: send_values, lang: i18n.language })
      .then(({ success, data, error, status }) => {
        if (success) {
          !choose && makeRefreshDepartures(refreshDepartures + 1);
          onSuccess(data);
          setLoading(false);
        } else {
          error && console.log(error);
        }
      });
  };

  const editDeparture = () => {
    setLoading(true);
    setLoadingLabel(t("common.loaders.edit_departure"));

    let locations = [
      { id: startLocation.id, order: 1 },
      { id: finishLocation.id, order: null },
    ];
    if (transitPoints) {
      locations = [
        ...locations,
        ...transitPoints.map((item, index) => ({
          id: item.id,
          order: index + 2,
        })),
      ];
    }
    let send_values = {
      ...values,
      locations: locations,
    };
    departureServices
      .editDeparture({ id: id, values: send_values, lang: i18n.language })
      .then(({ success, data, error, status }) => {
        setLoading(false);
        if (success) {
          onSuccessEdit(data);
        } else {
          error && console.log(error);
        }
      });
  };

  function handleSubmit(event) {
    event.preventDefault();
    let error = false;
    let errorTab = null;
    Object.entries(values).forEach(([key, value]) => {
      if (!value && key !== "description") {
        setErrors((oldErrors) => ({
          ...oldErrors,
          [key]: t("departures.create.errors.not_null"),
        }));
        error = true;
      } else {
        setErrors((oldErrors) => ({
          ...oldErrors,
          [key]: null,
        }));
      }
    });
    if (!startLocation) {
      setStartLocationError(t("departures.create.errors.not_null"));
      error = true;
      errorTab = 1;
    }
    if (!finishLocation) {
      setFinishLocationError(t("departures.create.errors.not_null"));
      error = true;
      errorTab = 1;
    }
    if (values.departure_time >= values.arrival_time) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        arrival_time: t("departures.create.errors.arr_gt_dep_times"),
      }));
      error = true;
      errorTab = 1;
    }
    if (
      shipmentDeadline &&
      compareAsc(parseISO(shipmentDeadline), values.arrival_time) === -1
    ) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        arrival_time: t("departures.create.errors.arr_gt_deadline_time"),
      }));
      error = true;
      errorTab = 1;
    }
    if (error) {
      errorTab === 1 && setTab(1);
      return false;
    }
    mode === "add" ? createDeparture() : editDeparture();
    return false;
  }

  const makeExcludeList = (initiator) => {
    let result = [];
    switch (initiator) {
      case "start":
        finishLocation
          ? (result = [...transitPoints, finishLocation])
          : (result = [...transitPoints]);
        return result;
      case "finish":
        startLocation
          ? (result = [...transitPoints, startLocation])
          : (result = [...transitPoints]);
        return result;
      case "tranzit":
        if (startLocation && finishLocation) {
          result = [...transitPoints, startLocation, finishLocation];
        } else if (startLocation) {
          result = [...transitPoints, startLocation];
        } else if (finishLocation) {
          result = [...transitPoints, finishLocation];
        } else if (transitPoints) {
          result = [...transitPoints];
        }
        return result;
    }
  };

  const parseData = (data) => {
    const routelocations = data.route.routelocations;
    const startLoc = routelocations.shift();
    const finishLoc = routelocations.pop();
    setStartLocation(startLoc.location);
    setFinishLocation(finishLoc.location);
    if (routelocations.length > 0) {
      let transit = [];
      routelocations.map((rlocation) => transit.push(rlocation.location));
      setTransitPoints(transit);
    }
    const result = {
      transport_type: data.transport_type.id,
      luggage_type: data.luggage_type.id,
      max_weight: data.max_weight.id,
      description: data.description,
      departure_time: data.departure_time,
      arrival_time: data.arrival_time,
    };
    setValues(result);
  };

  useEffect(() => {
    if (mode === "edit") {
      setLoading(true);
      setLoadingLabel(t("common.loaders.info"));

      const fetchData = () => {
        departureServices
          .getDeparture({ id, lang: i18n.language })
          .then(({ success, data, error, status }) => {
            if (success) {
              parseData(data);
            }
            setLoading(false);
          });
      };

      fetchData();
    }
  }, [mode, i18n.language]);

  return (
    <Container className="padding0">
      <ValidatorForm onSubmit={handleSubmit} className="fullWidthForm">
        <Grid item container spacing={2} direction="column">
          {tab === 1 && (
            <Fragment>
              <Grid item className="marginBottom8">
                <Typography variant="h6" component="h6">
                  {t("departures.create.route")}
                </Typography>
              </Grid>
              <Grid item className="marginBottom8">
                <SingleLocation
                  icon="start"
                  error={startLocationError}
                  label={t("departures.create.from.label")}
                  placeholder={t("departures.create.from.placeholder")}
                  type="cities"
                  selected={startLocation}
                  setSelected={(selected) => {
                    setStartLocation(selected);
                    setStartLocationError(false);
                  }}
                  excludeList={makeExcludeList("start")}
                />
              </Grid>

              <Grid item className="marginBottom8">
                <MultipleLocation
                  icon="tranzit"
                  label={t("departures.create.tranzit.label")}
                  placeholder={t("departures.create.tranzit.placeholder")}
                  selected={transitPoints}
                  type="cities"
                  setSelected={setTransitPoints}
                  excludeList={makeExcludeList("tranzit")}
                />
              </Grid>
              <Grid item className="marginBottom8">
                <SingleLocation
                  icon="finish"
                  error={finishLocationError}
                  label={t("departures.create.to.label")}
                  placeholder={t("departures.create.to.placeholder")}
                  type="cities"
                  selected={finishLocation}
                  setSelected={(selected) => {
                    setFinishLocation(selected);
                    setFinishLocationError(false);
                  }}
                  excludeList={makeExcludeList("finish")}
                />
              </Grid>
              <Grid item className="marginBottom8">
                <Typography variant="h6" component="h6">
                  {t("departures.create.dates")}
                </Typography>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item md={6} xs={12} className="marginBottom8">
                  {isMobile ? (
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={getDateLocale(i18n.language)}
                    >
                      <DateTimePicker
                        // autoOk
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <TodayIcon color="primary" />
                            </InputAdornment>
                          ),
                        }}
                        inputVariant="outlined"
                        disablePast
                        fullWidth
                        label={t("departures.create.start_date")}
                        placeholder={t("departures.create.start_date")}
                        helperText={
                          errors.departure_time && errors.departure_time
                        }
                        ampm={false}
                        value={values.departure_time}
                        onChange={dateTimeDepChange}
                        name="departure_time"
                        error={Boolean(errors.departure_time)}
                        locale={i18n.language}
                        minutesStep={5}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <TodayIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      value={
                        values.departure_time &&
                        format(
                          Date.parse(values.departure_time),
                          "dd.MM.yyyy HH:mm"
                        )
                      }
                      variant="outlined"
                      label={t("departures.create.start_date")}
                      placeholder={t("departures.create.start_date")}
                      onClick={() => setDepartureTimeModalOpen(true)}
                      helperText={
                        errors.departure_time && errors.departure_time
                      }
                      error={Boolean(errors.departure_time)}
                    />
                  )}

                  {departureTimeModalOpen && (
                    <DateTimePickerModal
                      open={departureTimeModalOpen}
                      handleClose={() => setDepartureTimeModalOpen(false)}
                      date={values.departure_time}
                      handleDateChange={dateTimeDepChange}
                    />
                  )}
                </Grid>
                <Grid item md={6} xs={12} className="marginBottom8">
                  {isMobile ? (
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={getDateLocale(i18n.language)}
                    >
                      <DateTimePicker
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EventIcon color="primary" />
                            </InputAdornment>
                          ),
                        }}
                        inputVariant="outlined"
                        fullWidth
                        label={t("departures.create.finish_date")}
                        placeholder={t("departures.create.finish_date")}
                        disablePast
                        minDate={values.departure_time}
                        ampm={false}
                        value={values.arrival_time}
                        onChange={dateTimeArrChange}
                        name="arrival_time"
                        helperText={errors.arrival_time && errors.arrival_time}
                        error={Boolean(errors.arrival_time)}
                        locale={i18n.language}
                        minutesStep={5}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EventIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      value={
                        values.arrival_time &&
                        format(
                          Date.parse(values.arrival_time),
                          "dd.MM.yyyy HH:mm"
                        )
                      }
                      variant="outlined"
                      label={t("departures.create.finish_date")}
                      placeholder={t("departures.create.finish_date")}
                      onClick={() => setArrivalTimeModalOpen(true)}
                      helperText={errors.arrival_time && errors.arrival_time}
                      error={Boolean(errors.arrival_time)}
                    />
                  )}

                  {arrivalTimeModalOpen && (
                    <DateTimePickerModal
                      open={arrivalTimeModalOpen}
                      handleClose={() => setArrivalTimeModalOpen(false)}
                      date={values.arrival_time}
                      handleDateChange={dateTimeArrChange}
                      maxValue={shipmentDeadline && shipmentDeadline}
                      minValue={values.departure_time && values.departure_time}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid item style={{ textAlign: "center" }}>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    margin: "8px",
                  }}
                  onClick={() => setTab(2)}
                >
                  {t("departures.create.next")}
                </Button>
              </Grid>
            </Fragment>
          )}

          {tab === 2 && (
            <Fragment>
              <Grid item className="marginBottom8">
                <Typography variant="h6" component="h6">
                  {t("departures.create.terms")}
                </Typography>
              </Grid>
              <Grid item className="marginBottom8">
                <TransportTypes
                  transport_type={values.transport_type}
                  handleChange={handleChange}
                  error={errors.transport_type}
                />
              </Grid>
              <Grid item className="marginBottom8">
                <WeightTypes
                  max_weight={values.max_weight}
                  handleChange={handleChange}
                  error={errors.max_weight}
                />
              </Grid>
              <Grid item className="marginBottom8">
                <LuggageTypes
                  luggage_type={values.luggage_type}
                  handleChange={handleChange}
                  error={errors.luggage_type}
                  transport_type={values.transport_type}
                  nullLuggageType={nullLuggageType}
                />
              </Grid>
              <Grid item className="marginBottom8">
                <Typography variant="h6" component="h6">
                  {t("departures.create.description.label")}
                </Typography>
              </Grid>
              <Grid item className="marginBottom8">
                <TextField
                  value={values.description}
                  name="description"
                  placeholder={t("departures.create.description.placeholder")}
                  fullWidth
                  multiline={true}
                  rows={3}
                  rowsMax={3}
                  variant="outlined"
                  onChange={handleChange}
                  inputProps={{ maxLength: 150 }}
                />
              </Grid>
              <Grid
                item
                className="marginBottom8"
                style={{ textAlign: "center" }}
              >
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    margin: "8px",
                  }}
                  onClick={() => setTab(1)}
                >
                  {t("departures.create.back")}
                </Button>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    margin: "8px",
                  }}
                  type="submit"
                >
                  {mode === "edit"
                    ? t("departures.edit.action_button")
                    : t("departures.create.action_button")}
                </Button>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </ValidatorForm>
    </Container>
  );
}
