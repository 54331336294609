import Avatar from "@material-ui/core/Avatar";
import { useTheme } from "@material-ui/styles";
import apiUrl from "Services/apiUrl";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core/styles";

function getSize(size) {
  return { width: `${size}em`, height: `${size}em` };
}

const UserAvatar = ({ profile, size }) => {
  const theme = useTheme();

  if (profile && profile.avatar) {
    let src = profile.avatar.startsWith("http")
      ? profile.avatar
      : `${apiUrl}${profile.avatar}`;
    return (
      <Avatar
        style={{
          ...getSize(size),
        }}
        src={src}
        alt={profile.first_name}
      />
    );
  } else {
    return (
      <Avatar
        aria-label="recipe"
        style={{
          ...getSize(size),
          ...{ backgroundColor: theme.palette.iconColor.main },
        }}
      >
        <b>{profile.first_name[0]}</b>
      </Avatar>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  profilePageAvatar: {
    backgroundColor: theme.palette.primary.main,
    border: `6px solid ${theme.palette.titleColor.main}`,
    boxShadow: "0 2px 5px 1px rgb(64 60 67 / 16%)",
  },
}));

const UserProfilePageAvatar = ({ profile, size }) => {
  const classes = useStyles();

  if (profile && profile.avatar) {
    let src = profile.avatar.startsWith("http")
      ? profile.avatar
      : `${apiUrl}${profile.avatar}`;
    return (
      <Avatar
        style={{
          ...getSize(size),
        }}
        className={classes.profilePageAvatar}
        src={src}
        alt={profile.first_name}
      />
    );
  } else {
    return (
      <Avatar
        aria-label="recipe"
        style={{
          ...getSize(size),
        }}
        className={classes.profilePageAvatar}
      >
        <b>{profile && profile.first_name[0] ? profile.first_name[0] : ""}</b>
      </Avatar>
    );
  }
};

const HeaderUserAvatar = ({ profile, size }) => {
  if (profile && profile.avatar) {
    let src = profile.avatar.startsWith("http")
      ? profile.avatar
      : `${apiUrl}${profile.avatar}`;
    return (
      <Avatar
        style={{
          ...getSize(size),
        }}
        src={src}
        alt={profile.first_name}
      />
    );
  } else {
    return <AccountCircle />;
  }
};

export { UserAvatar as default, HeaderUserAvatar, UserProfilePageAvatar };
