import { TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";

const validators = ["required", "minStringLength:6", "maxStringLength:6"];
const errors = [
  "Введите код подтверждения",
  "Код должен содержать 6 символов",
  "Код должен содержать 6 символов",
];

const ConfirmCode = ({ confirmCode, onChange }) => {
  const { t } = useTranslation();

  return (
    <TextValidator
      fullWidth
      autoFocus
      id="confirmCode"
      name="confirmCode"
      variant="outlined"
      label={t("inputs.confirm_code.title")}
      value={confirmCode}
      onChange={onChange}
      validators={validators}
      errorMessages={errors}
    />
  );
};

export default ConfirmCode;
