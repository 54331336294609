import { Fragment, useState } from "react";

import MessageIcon from "@material-ui/icons/Message";
import GradeIcon from "@material-ui/icons/Grade";
import NotificationItem from "./NotificationItem";
import { Divider, Badge } from "@material-ui/core";
import CommuteIcon from "@material-ui/icons/Commute";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  activeTab: {
    backgroundColor: `${theme.palette.primary.main}!important`,
  },
  tab: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.home.hero.color,
    backgroundColor: "#aaaaaa",
    width: "25%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabContent: {
    border: "2px solid #aaaaaa",
    borderTop: "0px",
  },
  noNotifications: {
    display: "flex",
    minHeight: "250px",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #aaaaaa",
    borderTop: "0px",
  },
  footer: {
    width: "100%",
    display: "flex",
    height: "50px",
    backgroundColor: "rgb(243, 243, 243)",
  },
}));

const TabIcon = ({ active, onClick, icon }) => {
  const classes = useStyles();

  return (
    <div
      className={`${active ? classes.activeTab : ""} ${classes.tab}`}
      onClick={onClick}
    >
      <div>{icon}</div>
    </div>
  );
};

const MessagesTab = ({ messages, newMessages, handleClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (
    (!messages ||
      messages.length === 0 ||
      Object.entries(messages).length === 0) &&
    (!newMessages ||
      newMessages.length === 0 ||
      Object.entries(newMessages).length === 0)
  ) {
    return (
      <div className={classes.noNotifications}>
        {t("notifications.no_new_messages")}
      </div>
    );
  }
  let mess = [];
  for (const [link, message] of Object.entries(messages)) {
    mess.push({ ...message, link, type: "message" });
  }
  let newMess = [];
  for (const [link, message] of Object.entries(newMessages)) {
    newMess.push({ ...message, link, type: "message" });
  }

  return (
    <div className={classes.tabContent}>
      {newMess.map((notification, index) => (
        <Fragment key={notification.link}>
          <NotificationItem
            notification={notification}
            handleClose={handleClose}
            readed={false}
          />
          {newMess.length - 1 !== index && <Divider />}
        </Fragment>
      ))}
      {mess.map((notification, index) => (
        <Fragment key={notification.link}>
          <NotificationItem
            notification={notification}
            handleClose={handleClose}
            readed={true}
          />
          {mess.length - 1 !== index && <Divider />}
        </Fragment>
      ))}
    </div>
  );
};

const SubscribtionsTab = ({
  subscriptions,
  newSubscriptions,
  type,
  handleClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (
    (!subscriptions ||
      subscriptions.length === 0 ||
      Object.entries(subscriptions).length === 0) &&
    (!newSubscriptions ||
      newSubscriptions.length === 0 ||
      Object.entries(newSubscriptions).length === 0)
  ) {
    return (
      <div className={classes.noNotifications}>
        {t("notifications.no_new_routes")}
      </div>
    );
  }
  let mess = [];
  for (const [link, subscription] of Object.entries(subscriptions)) {
    mess.push({ ...subscription, link, type: type });
  }
  let newMess = [];
  for (const [link, subscription] of Object.entries(newSubscriptions)) {
    newMess.push({ ...subscription, link, type: type });
  }

  return (
    <div className={classes.tabContent}>
      {newMess.map((notification, index) => (
        <Fragment key={notification.link}>
          <NotificationItem
            notification={notification}
            handleClose={handleClose}
            readed={false}
          />
          {newMess.length - 1 !== index && <Divider />}
        </Fragment>
      ))}
      {mess.map((notification, index) => (
        <Fragment key={notification.link}>
          <NotificationItem
            notification={notification}
            handleClose={handleClose}
            readed={true}
          />
          {mess.length - 1 !== index && <Divider />}
        </Fragment>
      ))}
    </div>
  );
};

const RatingsTab = ({ ratings, newRatings, handleClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (
    (!ratings || ratings.length === 0) &&
    (!newRatings || newRatings.length === 0)
  ) {
    return (
      <div className={classes.noNotifications}>
        {t("notifications.no_rating")}
      </div>
    );
  }

  return (
    <div className={classes.tabContent}>
      {newRatings &&
        newRatings.map((notification, index) => (
          <Fragment key={notification.link}>
            <NotificationItem
              notification={{ ...notification, type: "rating" }}
              handleClose={handleClose}
              readed={false}
            />
            {newRatings.length - 1 !== index && <Divider />}
          </Fragment>
        ))}
      {ratings &&
        ratings.map((notification, index) => (
          <Fragment key={notification.link}>
            <NotificationItem
              notification={{ ...notification, type: "rating" }}
              handleClose={handleClose}
              readed={true}
            />
            {ratings.length - 1 !== index && <Divider />}
          </Fragment>
        ))}
    </div>
  );
};

const Tabs = ({ children, activeTab }) => {
  return children.find((child) => child.props.tab === activeTab);
};

const NotificationList = ({ notifications, handleClose }) => {
  const [active, setActive] = useState(1);
  const classes = useStyles();
  return (
    <div style={{ width: "100%" }}>
      <div className={classes.footer}>
        <TabIcon
          active={active === 1}
          onClick={() => setActive(1)}
          name="Messages"
          icon={
            <Badge
              color="secondary"
              badgeContent={
                notifications?.unreaded?.messages
                  ? Object.keys(notifications.unreaded.messages).length
                  : 0
              }
            >
              <MessageIcon />
            </Badge>
          }
        />
        <TabIcon
          active={active === 2}
          onClick={() => setActive(2)}
          name="Departures"
          icon={
            <Badge
              color="secondary"
              badgeContent={
                notifications?.unreaded?.subDepartures
                  ? Object.keys(notifications.unreaded.subDepartures).length
                  : 0
              }
            >
              <CommuteIcon />
            </Badge>
          }
        />
        <TabIcon
          active={active === 3}
          onClick={() => setActive(3)}
          name="Shipments"
          icon={
            <Badge
              color="secondary"
              badgeContent={
                notifications?.unreaded?.subShipments
                  ? Object.keys(notifications.unreaded.subShipments).length
                  : 0
              }
            >
              <CardTravelIcon />
            </Badge>
          }
        />
        <TabIcon
          active={active === 4}
          onClick={() => setActive(4)}
          name="Ratings"
          icon={
            <Badge
              color="secondary"
              badgeContent={
                notifications?.unreaded?.ratings
                  ? Object.keys(notifications.unreaded.ratings).length
                  : 0
              }
            >
              <GradeIcon />
            </Badge>
          }
        />
      </div>
      <div>
        {notifications && (
          <Tabs activeTab={active}>
            <MessagesTab
              tab={1}
              messages={notifications.readed.messages}
              newMessages={notifications.unreaded.messages}
              handleClose={handleClose}
            />
            <SubscribtionsTab
              tab={2}
              subscriptions={notifications.readed.subDepartures}
              newSubscriptions={notifications.unreaded.subDepartures}
              type="departures"
              handleClose={handleClose}
            />
            <SubscribtionsTab
              tab={3}
              subscriptions={notifications.readed.subShipments}
              newSubscriptions={notifications.unreaded.subShipments}
              type="shipments"
              handleClose={handleClose}
            />
            <RatingsTab
              tab={4}
              ratings={notifications.readed.ratings}
              newRatings={notifications.unreaded.ratings}
              handleClose={handleClose}
            />
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default NotificationList;
