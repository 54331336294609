import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import ServerDownPage from "Pages/ServerDown";
import initAppService from "Services/initAppService";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

const initApp = async () => {
  const { data, success } = await initAppService();
  let welcomeOpenInit;

  const siteVisits = getCookie("welcome");
  if (!siteVisits) {
    document.cookie = "welcome=1;expires=Tue, 19 Jan 2038 04:14:07 GMT";
    welcomeOpenInit = true;
  } else {
    if (parseFloat(siteVisits) > 3) welcomeOpenInit = false;
    else {
      document.cookie = `welcome=${
        parseFloat(siteVisits) + 1
      };expires=Tue, 19 Jan 2038 04:14:07 GMT`;
      welcomeOpenInit = true;
    }
  }
  const app = success ? (
    <App data={data} welcomeOpenInit={welcomeOpenInit} />
  ) : (
    <ServerDownPage />
  );

  ReactDOM.render(app, document.getElementById("vezoo"));
};

initApp();
